import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import AppAdmin from '../appMenu/appAdmin';
import styled from 'styled-components';
import React, { useState, useEffect, createElement} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";


const Divider = styled.div`
  position: relative;
  height: 1px;
`;

const DivTransparent = styled(Divider)`
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
  }
`;

const DivArrowDown = styled(DivTransparent)`
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -6.5px;
    left: calc(75% - 7px);
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    background-color: white;
    border-bottom: 1px solid rgb(48,49,51);
    border-right: 1px solid rgb(48,49,51);
  }
`;



function GerarProcesso() {

  const token = Cookies.get('ANers');
  const {id} = useParams();
  const [authenticated, setAuthenticated] = useState(false);
  const [responseProcess, setResponse] = useState(false)
  const [responseForum, setResponseForum] = useState(false)
  const [responseCliente, setResponseCliente] = useState(false)
  const [responsePessoa, setResponsePessoa] = useState(false)
  const [user, setUser] = useState(false)
  const [valueCel, setValueCel] = useState('');
  const [valueCpf, setValueCpf] = useState('');

  useEffect(() => {
    axios.post('https://guias.almeidaenogueira.com.br/api/getgenerate', {token}, {
      withCredentials: true,
    })
    .then((response) => {
      if (response.data.auth === true) {
        setAuthenticated(true);
        setResponse(response.data.resp)
        setResponseForum(response.data.resForum)
        setResponseCliente(response.data.resCliente)
	      setUser(response.data.user)
	setResponsePessoa(response.data.resPessoa)
      } else {
          window.location = '/login';
      }
    })
    .catch(() => {
      window.location = '/login';
    });
  }, []);

  const [value, setValue] = useState('');

  function handleInputChange(event) {
    const { value, name } = event.target;

    const newValue = value
      .replace(/\D/g, '') // Remove tudo que não for número
      .replace(/^0+/, '') // Remove os zeros iniciais
      .padStart(3, '0') // Adiciona zeros à esquerda, caso necessário
      .replace(/(\d{1,})(\d{2})$/, '$1,$2') // Adiciona a vírgula decimal
      .replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona o ponto como separador de milhares

    setValue(`R$ ${newValue}`);
  }


  if (!authenticated) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>;
  }
  
  function handleInputChangeCel(event) {

    let { value } = event.target;

    // Remove tudo que não for número
    value = value.replace(/\D/g, '');

    if (value.length > 11) {
      // Limita o número de caracteres a 11
      value = value.slice(0, 11);
    }
  
    if (value.length === 11) {
      // Formata o número de celular
      const formattedValue = value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      setValueCel(formattedValue);
    } else {
      // Caso contrário, exibe o valor sem formatação
      setValueCel(value);
    }
  }

  function handleInputChangeCpf(event) {
    const { value } = event.target;
    console.log('cpf formatação')
    // Remove tudo que não for número
    const newValue = value.replace(/\D/g, '');
  
    // Verifica se o valor tem 11 dígitos
    if (newValue.length === 11) {
      // Formata o CPF
      const formattedValue = newValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      setValueCpf(formattedValue);
     } else if (newValue.length === 14) {
       // Formata o CNPJ
       const formattedValue = newValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
       setValueCpf(formattedValue);
     } else {
       // Caso contrário, exibe o valor sem formatação
       setValueCpf(newValue);
     }
   
  }

  function cadastrarPessoa(){
    const nome = document.querySelector('#nome').value
    const telefone = document.querySelector('#telefone').value
    const cpf_cnpj = document.querySelector('#cpf_cnpj').value
  
    axios.post(`https://guias.almeidaenogueira.com.br/api/new/partner/${id}`, {token, nome, telefone, cpf_cnpj}, {
      withCredentials: true,
    }).then((resp)=>{
      if(resp.data == 400){
        alert("Essa pessoa/cpf já está cadastrado!")
      }
      if(resp.data == 404){
        window.location = `/new/partner/erro`
      }
      if(resp.data.erros.length === 0){
        window.location.reload();
      } else {
        for(let i=0; i<resp.data.erros.length; i++){
          let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`)
          elemento.style.backgroundColor = '#FFC0CB'
        }
      }
    })
  }

  function setGenerate(){
    const processo = document.querySelector('#processo').value
    const valorEstimado = document.querySelector('#valorEstimado').value
    const forum = document.querySelector('#forum').value
    const cliente = document.querySelector('#cliente').value
    const inicioProcesso = document.querySelector('#inicioProcesso').value
    const acao = document.querySelector('#acao').value
    const pasta = document.querySelector('#pasta').value
    const autor = document.querySelector('#autor').value
    const reu = document.querySelector('#reu').value
    const advogado = document.querySelector('#advogado').value
    const advAutor =  document.querySelector('#advAutor').value
    const observacao = document.querySelector('#observacao').value
    const divErros = document.querySelector('#erros')
    const excluir = divErros.querySelectorAll('.row')
    for(let cont=0; cont<excluir.length;cont++){
      excluir[cont].remove()
    }
      
    axios.post('/api/generate/process', {token, processo, valorEstimado, forum, cliente, inicioProcesso, acao, pasta, observacao, reu, autor, advogado, advAutor}, {
      withCredentials: true,
    }).then((resp)=>{
      if(resp.data.erros.length === 0){
        window.location = `/ci/pagamentos/${resp.data.id}`
      } else {
        for(let i=0; i<resp.data.erros.length; i++){
          
          let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`)
          elemento.style.backgroundColor = '#FFC0CB'
          let divRow = document.createElement('div') // Cria o elemento div
          divRow.className = 'row mt-3' // Adiciona uma margem superior de 3 unidades
          //divRow.style.backgroundColor = '#FFC0CB' // Define a cor de fundo da div
          divRow.style.padding = '10px' // Define um espaçamento interno de 10 pixels
          divRow.style.borderRadius = '5px' // Define um raio de borda de 5 pixels
          let divCol = document.createElement('div') // Cria o elemento div
          divCol.style.display = 'inline-block'
          divCol.style.backgroundColor = '#FFC0CB'
          let divText = document.createTextNode(`${resp.data.erros[i].mensagem}`) // Cria o elemento de texto
          divCol.appendChild(divText) // Adiciona o texto como filho de divCol
          divRow.appendChild(divCol)
          divErros.appendChild(divRow)
        }
        
      }
    })
  }

  if(user.id == 1){
  return (
    <>
      <AppHeader response={user}/>
      <AppAdmin />
      <div className="content-wrapper" style={{minHeight: 675}}>
      <section class= "content">
      <div className="container-fluid" style={{marginTop: 18}}>

         <div class="row">
          <div class="col-12">
            <div class="card">
            <div class="card-header bg-info">
            <div class="inner text-center"><img src="/dist/img/martelo.png" />
</div>  
          <h3 className="text-center">Controle de CI</h3>

          </div> 
              <div class="card-body">
                <div class="row">
                <div className="col-lg-6" style={{marginBottom: 10}}>
                <a href='/ci/controle'>
                  <div class="text-center">
                  <font color="#212529"><span>Consultar Processos</span></font>
                    </div>
                    </a>
                    </div>
                    <div class="col-lg-6">
                    <a href='/ci/novo/processo'>
                  <div class="text-center">
                    <span><font color="#17a2b8">Gerar Novo Processo</font></span>
                    </div>
                    </a>
                    </div>
                </div>
                <DivArrowDown />
                <section>
                  <h5 style={{marginTop: 15}}>Gerar Novo Processo</h5>
                 <div id='erros'></div>
                  <div className="row" style={{marginTop: 25}}>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Processo</label>
                        <input type="text" class="form-control" id="processo" placeholder="Número do Processo do Documento" name='processo'></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Valor Estimado da Causa</label>
                        <input type="text" value={value} class="form-control" id="valorEstimado" onChange={handleInputChange}   name='valorEstimado'></input>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">  
                      <div className="form-group">
                        <label className="required">Fórum</label>
                        <div class="input-group">
    <div class="input-group-prepend">
      <button class="btn btn-outline-success" type="button"  data-toggle="modal" data-target="#exampleModal">Adicionar</button>
    </div>
                        <select className="form-control" id="forum" name='forum'>
                        {
                            responseForum.map(res=>(
  <option>{res.descricao}</option>
  ))}
</select>
                      </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                        <label className="required">Selecione o cliente</label>
                        <select className="form-control" id="cliente" name='cliente'>
                          {
                            responseCliente.map(resp=> (
                            <option>{resp.nome}</option>
                          ))}
</select>

                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Data do Início do Processo</label>
                        <div class="input-group date" data-target-input="nearest">
                          <input type="date" className="form-control"  id="inicioProcesso" name='inicioProcesso'/>
                                </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Selecione uma Ação</label>
                        <select className="form-control" id="acao" name='acao'>
                          {
                            responseProcess.map(resp=> (
                            <option>{resp.nome}</option>
                          ))}
</select>

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Informe o Número da Pasta</label>
                        <input type="text" class="form-control" id="pasta" placeholder="" name='pasta'></input>
                      </div>
                    </div>
                  </div>
	          <div className="row">
	            <div className="col-md-6">
	  	      <div className="form-group">
                        <label>Advogado (a) réu</label>
                        <div
                            className="div-button-success text-white"
                            title="Adicionar Nova Pessoa"
                            data-toggle="modal"
                            data-target="#novaentidade"
                        >
                            <i
                                className="fas fa-plus"
                                style={{ marginLeft: "5px" }}
                            ></i>
                            Adicionar Nova Pessoa
                        </div>
                        <select
                            className="form-control select2 select2-hidden-accessible"
                            name="advogado"
                            style={{ width: "100%" }}
                            id="advogado"
                        >
                            <option selected="selected">
                                {responseProcess.advogado}
                            </option>
                            {responsePessoa.map((dados) => (
                                <option>{dados.nome}</option>
                            ))}
                        </select>
                    </div>
	            </div>
	            <div className="col-md-6">
	  		<div className="form-group">
                        <label>Réu</label>
                        <div
                            className="div-button-success text-white"
                            title="Adicionar Nova Pessoa"
                            data-toggle="modal"
                            data-target="#novaentidade"
                        >
                            <i
                                className="fas fa-plus"
                                style={{ marginLeft: "5px" }}
                            ></i>
                            Adicionar Nova Pessoa
                        </div>
                        <select
                            className="form-control select2 select2-hidden-accessible"
                            style={{ width: "100%" }}
                            name="reu"
                            id="reu"
                        >
                            <option selected="selected">
                                {responseProcess.reu}
                            </option>
                            {responsePessoa.map((dados) => (
                                <option>{dados.nome}</option>
                            ))}
                        </select>
                    </div>
	            </div>
	           </div>
	           <div className="row">
	            <div className="col-md-6">
	  		<div className="form-group">
                        <label>Advogado (a) autor</label>
                        <div
                            className="div-button-success text-white"
                            title="Adicionar Nova Pessoa"
                            data-toggle="modal"
                            data-target="#novaentidade"
                        >
                            <i
                                className="fas fa-plus"
                                style={{ marginLeft: "5px" }}
                            ></i>
                            Adicionar Nova Pessoa
                        </div>
                        <select
                            className="form-control select2 select2-hidden-accessible"
                            style={{ width: "100%" }}
                            name="advAutor"
                            id="advAutor"
                        >
                            <option selected="selected">
                                {responseProcess.advAutor}
                            </option>
                            {responsePessoa.map((dados) => (
                                <option>{dados.nome}</option>
                            ))}
                        </select>
                    </div>
	  	    </div>
	  	    <div className="form-group">
                        <label>Autor</label>
                        <div
                            className="div-button-success text-white"
                            title="Adicionar Nova Pessoa"
                            data-toggle="modal"
                            data-target="#novaentidade"
                        >
                            <i
                                className="fas fa-plus"
                                style={{ marginLeft: "5px" }}
                            ></i>
                            Adicionar Nova Pessoa
                        </div>
                        <select
                            className="form-control select2 select2-hidden-accessible"
                            style={{ width: "100%" }}
                            name="autor"
                            id="autor"
                        >
                            <option selected="selected">
                                {responseProcess.autor}
                            </option>
                            {responsePessoa.map((dados) => (
                                <option>{dados.nome}</option>
                            ))}
                        </select>
                    </div>
	          </div>
                  <div className="row">
                  <div className="col-md-12">
                      <div className="form-group">
                        <label>Observação</label>
                        <textarea class="form-control" id="observacao" rows="6" name='observacao'></textarea>
                      </div>
                    </div>
                  </div>
                  <button type='button' class='btn btn-success' onClick={setGenerate}>Salvar</button>
                 
                </section>

              </div>
            </div>
          </div>
      </div>
      </div>
      </section>
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Cadastrar Novo Fórum</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form method='post' action='/api/new/forum'>
      <div class="modal-body">
          <label for="m-cadastrar-descricaoLbl">Descrição</label>
          <input type="text" id="m-cadastrar-descricao" className="form-control" name="descricao" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        <button type='submit' class="btn btn-primary">Salvar</button>
      </div>
      </form>
    </div>
  </div>
</div>
      
      <div class="modal fade" id="novaentidade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
      <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nova Pessoa</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <label className="required" for="m-cadastrar-descricaoLbl">Nome</label>
          <input id="nome" className='form-control' name='nome'></input>
          <label className="required" for="m-cadastrar-descricaoLbl">Telefone</label>
          <input id="telefone" value={valueCel} className='form-control' name="telefone" maxLength={14} onChange={handleInputChangeCel}></input>
          <label className="required" for="m-cadastrar-descricaoLbl">CPF/CNPJ</label>
          <input id="cpf_cnpj" maxLength={18} className='form-control' name="cpf_cnpj" value={valueCpf} onChange={handleInputChangeCpf}></input>
          
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" onClick={cadastrarPessoa}>Salvar</button>
      </div>
      </div>
      </div>
      </div>

      </div>
      <AppFooter />
      <AppSetting />
    </>  

  );}else{

  return (
    <>
      <AppHeader response={user} />
      <AppMenu />
      <div className="content-wrapper" style={{minHeight: 675}}>
      <section class= "content">
      <div className="container-fluid" style={{marginTop: 18}}>

         <div class="row">
          <div class="col-12">
            <div class="card">
            <div class="card-header bg-info">
            <div class="inner text-center"><img src="/dist/img/martelo.png" />
</div>  
          <h3 className="text-center">Controle de CI</h3>

          </div> 
              <div class="card-body">
                <div class="row">
                <div className="col-lg-6" style={{marginBottom: 10}}>
                <a href='/ci/controle'>
                  <div class="text-center">
                  <font color="#212529"><span>Consultar Processos</span></font>
                    </div>
                    </a>
                    </div>
                    <div class="col-lg-6">
                    <a href='/ci/novo/processo'>
                  <div class="text-center">
                    <span><font color="#17a2b8">Gerar Novo Processo</font></span>
                    </div>
                    </a>
                    </div>
                </div>
                <DivArrowDown />
                <section>
                  <h5 style={{marginTop: 15}}>Gerar Novo Processo</h5>
                 <div id='erros'></div>
                  <div className="row" style={{marginTop: 25}}>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Processo</label>
                        <input type="text" class="form-control" id="processo" placeholder="Número do Processo do Documento" name='processo'></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Valor Estimado da Causa</label>
                        <input type="text" value={value} class="form-control" id="valorEstimado" onChange={handleInputChange}   name='valorEstimado'></input>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">  
                      <div className="form-group">
                        <label className="required">Fórum</label>
                        <div class="input-group">
    <div class="input-group-prepend">
      <button class="btn btn-outline-success" type="button"  data-toggle="modal" data-target="#exampleModal">Adicionar</button>
    </div>
                        <select className="form-control" id="forum" name='forum'>
                        {
                            responseForum.map(res=>(
  <option>{res.descricao}</option>
  ))}
</select>
                      </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                        <label className="required">Selecione o cliente</label>
                        <select className="form-control" id="cliente" name='cliente'>
                          {
                            responseCliente.map(resp=> (
                            <option>{resp.nome}</option>
                          ))}
</select>

                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Data do Início do Processo</label>
                        <div class="input-group date" data-target-input="nearest">
                          <input type="date" className="form-control"  id="inicioProcesso" name='inicioProcesso'/>
                                </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Prazo Estimado da Entrega</label>
                        <div class="input-group date" data-target-input="nearest">
                        <input type="date" className="form-control" id="estimada" name='estimada'/>

                                </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Selecione uma Ação</label>
                        <select className="form-control" id="acao" name='acao'>
                          {
                            responseProcess.map(resp=> (
                            <option>{resp.nome}</option>
                          ))}
</select>

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="required">Informe o Número da Pasta</label>
                        <input type="text" class="form-control" id="pasta" placeholder="" name='pasta'></input>
                      </div>
                    </div>
                  </div>
	          <div className="row">
	            <div className="col-md-6">
	              <div className="form-group">
	                <label>Informe o advogado (a) do autor </label>
	                <input type="text" className="form-control" id="advAutor" placeholder="" name="advAutor" />
	              </div>
	            </div>
	            <div className="col-md-6">
	              <div className="form-group">
	                <label>Informe Autor</label>
	                <input type="text" className="form-control" id="autor" placeholder="" name="autor" />
	              </div>
	            </div>
	          </div>
	          <div className="row">
	            <div className="col-md-6">
	              <div className="form-group">
	                <label>Informe o advogado (a) do réu</label>
	                <input type="text" className="form-control" id="advogado" placeholder="" name="advogado" />
	              </div>
	            </div>
	            <div className="col-md-6">
	              <div className="form-group">
	                <label>Informe Réu</label>
	                <input type="text" className="form-control" id="reu" placeholder="" name="reu" />
	              </div>
	            </div>
	          </div>
                  <div className="row">
                  <div className="col-md-12">
                      <div className="form-group">
                        <label>Observação</label>
                        <textarea class="form-control" id="observacao" rows="6" name='observacao'></textarea>
                      </div>
                    </div>
                  </div>
                  <button type='button' class='btn btn-success' onClick={setGenerate}>Salvar</button>
                 
                </section>

              </div>
            </div>
          </div>
      </div>
      </div>
      </section>
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Cadastrar Novo Fórum</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form method='post' action='/api/new/forum'>
      <div class="modal-body">
          <label for="m-cadastrar-descricaoLbl">Descrição</label>
          <input type="text" id="m-cadastrar-descricao" className="form-control" name="descricao" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        <button type='submit' class="btn btn-primary">Salvar</button>
      </div>
      </form>
    </div>
  </div>
</div>
      </div>
      <AppFooter />
      <AppSetting />
    </>  

  );}
}

export default GerarProcesso;

/*<div className="content-wrapper" style={{minHeight: 675}}>
      <div class="container-fluid">
      <div class="divider div-transparent div-arrow-down"></div>
      </div>
      </div>*/

function NoMatch(){
    return(
        <div className="container">
  <div className="row">
    <div className="col-md-12">
      <div className="error-template">
        <h1>
          Oops!</h1>
        <h2>
          404, Nada foi encontrado</h2>
        <div className="error-details">
          Desculpe, ocorreu um erro, a página solicitada não foi encontrada!
        </div>
        <div className="error-actions">
          <a href="/" className="btn btn-primary btn-lg"><span className="glyphicon glyphicon-home" />
            Me leve para o Início </a><a href="/suporte" className="btn btn-default btn-lg"><span className="glyphicon glyphicon-envelope" /> Entre em contato com o suporte </a>
        </div>
      </div>
    </div>
  </div>
</div>

    )
}

export default NoMatch

import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';



function Teste(){


    return(
        <>
        <AppHeader />
        <AppMenu />
        
<div className="content-wrapper" style={{minHeight: 675}}>
      <section class= "content">
      <div className="container-fluid" style={{marginTop: 18}}>
       
         <div class="row">
          <div class="col-12">
            <div class="card">
           <div className="card-body">
  <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4">
    <div className="row">
      <div className="col-sm-12 col-md-6">
    
    </div>
    
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <table id="example1" className="table table-bordered table-striped dataTable dtr-inline" aria-describedby="example1_info">
          <thead>
            <tr><th className="sorting sorting_asc" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="Rendering engine: activate to sort column descending" aria-sort="ascending">Rendering engine</th><th className="sorting" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="Browser: activate to sort column ascending">Browser</th><th className="sorting" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="Platform(s): activate to sort column ascending">Platform(s)</th><th className="sorting" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="Engine version: activate to sort column ascending">Engine version</th><th className="sorting" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="CSS grade: activate to sort column ascending">CSS grade</th></tr>
          </thead>
          <tbody>
            <tr className="odd">
              <td className="dtr-control sorting_1" tabIndex={0}>teste</td>
              <td>Firefox 1.0</td>
              <td>Win 98+ / OSX.2+</td>
              <td>1.7</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Firefox 1.5</td>
              <td>Win 98+ / OSX.2+</td>
              <td>1.8</td>
              <td>A</td>
            </tr><tr className="odd">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Firefox 2.0</td>
              <td>Win 98+ / OSX.2+</td>
              <td>1.8</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Firefox 3.0</td>
              <td>Win 2k+ / OSX.3+</td>
              <td>1.9</td>
              <td>A</td>
            </tr><tr className="odd">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Camino 1.0</td>
              <td>OSX.2+</td>
              <td>1.8</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Camino 1.5</td>
              <td>OSX.3+</td>
              <td>1.8</td>
              <td>A</td>
            </tr><tr className="odd">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Netscape 7.2</td>
              <td>Win 95+ / Mac OS 8.6-9.2</td>
              <td>1.7</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Netscape Browser 8</td>
              <td>Win 98SE+</td>
              <td>1.7</td>
              <td>A</td>
            </tr><tr className="odd">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Netscape Navigator 9</td>
              <td>Win 98+ / OSX.2+</td>
              <td>1.8</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Mozilla 1.0</td>
              <td>Win 95+ / OSX.1+</td>
              <td>1</td>
              <td>A</td>
            </tr>
            <tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Mozilla 1.0</td>
              <td>Win 95+ / OSX.1+</td>
              <td>1</td>
              <td>A</td>
            </tr>
            <tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Mozilla 1.0</td>
              <td>Win 95+ / OSX.1+</td>
              <td>1</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Mozilla 1.0</td>
              <td>Win 95+ / OSX.1+</td>
              <td>1</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Mozilla 1.0</td>
              <td>Win 95+ / OSX.1+</td>
              <td>1</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Mozilla 1.0</td>
              <td>Win 95+ / OSX.1+</td>
              <td>1</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Mozilla 1.0</td>
              <td>Win 95+ / OSX.1+</td>
              <td>1</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Mozilla 1.0</td>
              <td>Win 95+ / OSX.1+</td>
              <td>1</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Mozilla 1.0</td>
              <td>Win 95+ / OSX.1+</td>
              <td>1</td>
              <td>A</td>
            </tr><tr className="even">
              <td className="dtr-control sorting_1" tabIndex={0}>Gecko</td>
              <td>Mozilla 1.0</td>
              <td>Win 95+ / OSX.1+</td>
              <td>1</td>
              <td>A</td>
            </tr>
            </tbody>
          
          <tfoot>
            <tr><th rowSpan={1} colSpan={1}>Rendering engine</th><th rowSpan={1} colSpan={1}>Browser</th><th rowSpan={1} colSpan={1}>Platform(s)</th><th rowSpan={1} colSpan={1}>Engine version</th><th rowSpan={1} colSpan={1}>CSS grade</th></tr>
          </tfoot>
        </table></div></div>
        
                                </div>
</div>
<div className="card-body">
  <div className="row">
    <div className="col-md-6" data-select2-id={30}>
    <div className="form-group">
  <label>Minimal</label>
  <select className="form-control select2" style={{width: '100%'}}>
    <option selected="selected">Alabama</option>
    <option>Alaska</option>
    <option>California</option>
    <option>Delaware</option>
    <option>Tennessee</option>
    <option>Texas</option>
    <option>Washington</option>
  </select>
</div>

      <div className="form-group">
        <label>Disabled</label>
        <select className="form-control select2 select2-hidden-accessible" disabled style={{width: '100%'}} data-select2-id={4} tabIndex={-1} aria-hidden="true">
          <option selected="selected" data-select2-id={6}>Alabama</option>
          <option>Alaska</option>
          <option>California</option>
          <option>Delaware</option>
          <option>Tennessee</option>
          <option>Texas</option>
          <option>Washington</option>
        </select>
        
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Multiple</label>
        <select className="select2 select2-hidden-accessible" multiple data-placeholder="Select a State" style={{width: '100%'}} data-select2-id={7} tabIndex={-1} aria-hidden="true">
          <option>Alabama</option>
          <option>Alaska</option>
          <option>California</option>
          <option>Delaware</option>
          <option>Tennessee</option>
          <option>Texas</option>
          <option>Washington</option>
        </select>
       
      </div>
      <div className="form-group">
        <label>Disabled Result</label>
        <select className="form-control select2 select2-hidden-accessible" style={{width: '100%'}} data-select2-id={9} tabIndex={-1} aria-hidden="true">
          <option selected="selected" data-select2-id={11}>Alabama</option>
          <option>Alaska</option>
          <option disabled="disabled">California (disabled)</option>
          <option>Delaware</option>
          <option>Tennessee</option>
          <option>Texas</option>
          <option>Washington</option>
        </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id={10} style={{width: '100%'}}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-2hog-container"><span className="select2-selection__rendered" id="select2-2hog-container" role="textbox" aria-readonly="true" title="Alabama">Alabama</span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
      </div>
    </div>
  </div>
  <h5>Custom Color Variants</h5>
  <div className="row">
    <div className="col-12 col-sm-6">
      <div className="form-group">
        <label>Minimal (.select2-danger)</label>
        <select className="form-control select2 select2-danger select2-hidden-accessible" data-dropdown-css-class="select2-danger" style={{width: '100%'}} data-select2-id={12} tabIndex={-1} aria-hidden="true">
          <option selected="selected" data-select2-id={14}>Alabama</option>
          <option>Alaska</option>
          <option>California</option>
          <option>Delaware</option>
          <option>Tennessee</option>
          <option>Texas</option>
          <option>Washington</option>
        </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id={13} style={{width: '100%'}}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-35p5-container"><span className="select2-selection__rendered" id="select2-35p5-container" role="textbox" aria-readonly="true" title="Alabama">Alabama</span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
      </div>
    </div>
    <div className="col-12 col-sm-6">
      <div className="form-group">
        <label>Multiple (.select2-purple)</label>
        <div className="select2-purple">
          <select className="select2 select2-hidden-accessible" multiple data-placeholder="Select a State" data-dropdown-css-class="select2-purple" style={{width: '100%'}} data-select2-id={15} tabIndex={-1} aria-hidden="true">
            <option>Alabama</option>
            <option>Alaska</option>
            <option>California</option>
            <option>Delaware</option>
            <option>Tennessee</option>
            <option>Texas</option>
            <option>Washington</option>
          </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id={16} style={{width: '100%'}}><span className="selection"><span className="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={-1} aria-disabled="false"><ul className="select2-selection__rendered"><li className="select2-search select2-search--inline"><input className="select2-search__field" type="search" tabIndex={0} autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" role="searchbox" aria-autocomplete="list" placeholder="Select a State" style={{width: '609.5px'}} /></li></ul></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
        </div>
      </div>
    </div>
  </div>
</div>


            </div>
          </div>
      </div>
      </div>
      </section>
      </div>
      
        <AppFooter />
        <AppSetting />
        </>
    )
}

export default Teste;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";


function Cliente ({response}){
  const {id} = useParams();
    const token = Cookies.get('ANers');
    const [authenticated, setAuthenticated] = useState(false);
    const [responseProcess, setResponse] = useState(false);
    const [responseUsuario, setResponseUsuario] = useState(false);
    const [responseReu, setResponseReu] = useState(false);
    const [responseAutor, setResponseAutor] = useState(false);
    const [responseAdvogado, setResponseAdvogado] = useState(false);
    const [responsePagamento, setResponsePagamento] = useState(false);
    const [responseWorkflow, setResponseWorkflow] = useState(false);
    const [responseAnexo, setResponseAnexo] = useState(false);
    const [selectPaymentId, setResponsePaymentId] = useState(false);
    const [responseTimeLine, setResponseTimeLine] = useState(false);
    const [timeLineAlternative, setTimeLineAlternative] = useState(false);
    const [responseDocs, setResponseDocs] = useState(false);


    useEffect(() => {
            if (response.data.auth === true) {
                setAuthenticated(true);
                console.log(response.data.resp[0])
                console.log('===='+authenticated)
                setResponse(response.data.resp)
                setResponseUsuario(response.data.usuario)
                setResponseReu(response.data.reus)
                setResponseAutor(response.data.autores)
                setResponseAdvogado(response.data.advogados)
                setResponsePagamento(response.data.pagamentos)
                setResponseWorkflow(response.data.workflows)
                setResponseAnexo(response.data.anexos)
                setResponseTimeLine(response.data.timeline)
                setResponseDocs(response.data.docs)
          } else {
            window.location = '/login';
          }
    }, []);
  
    if (!authenticated) {
        return <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}>
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>;
      }
    function dataFormato(date){
        const dataAtual = new Date(date);
        return `${dataAtual.getDate()<10 ? '0': ''}${dataAtual.getDate()}/${dataAtual.getMonth()+1<10 ? "0": ""}${dataAtual.getMonth()+1}/${dataAtual.getFullYear()}`
    }  

    function money(){
        let total = 0;
        responsePagamento.map(resp=>{
          let currency = resp.valor.replace(/\D/g, ''); 
          let curr= parseFloat(currency)/100;
          total = total + curr;
      })
      
      let totalF = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)
      
      return totalF;
      }
    
    function clickUpload(){
        const inputFile = document.getElementById('upload2');
        inputFile.click();
    }

    let fileList = new DataTransfer();


    const handleDocsFile = (event) => {
  let file = event.target.files;
  
  for(let i=0; i<file.length; i++){
    fileList.items.add(file[i]);
    const divUpload = document.querySelector('#divDoc');
  //const newAtt = document.querySelector('#newAttach');
  const novos = document.querySelector('#novoDocumento');
  const rowDiv = document.createElement('div');
  rowDiv.className = 'row';

  const col7Div = document.createElement('div');
  col7Div.className = 'col-12';
  
  const label = document.createElement('label');
  label.textContent = `${file[i].name}`
  const spanUpload = document.createElement('span');
  spanUpload.className = 'hidden-xs'
  const iconUp = document.createElement('i');
  iconUp.className = 'fa fa-minus-circle ml-3 btn-remove-anexo text-danger'
  iconUp.style.fontSize = '1.25em'
  iconUp.style.position = 'relative'
  iconUp.style.top = '.15em'
  iconUp.onclick = ()=>removeDocument(i)
  spanUpload.appendChild(iconUp)
  col7Div.appendChild(label);
  col7Div.appendChild(spanUpload);
  rowDiv.appendChild(col7Div);
  
  //divUpload.insertBefore(rowDiv, newAtt)
  novos.appendChild(rowDiv)

  }

  event.target.files = fileList.files;
  console.log('Files: '+file)

  const newDivUpload = document.querySelector('#novoDocumento');
  const icon = newDivUpload.querySelectorAll('i');
  for(let i = 0; i < icon.length; i++){
    
    icon[i].onclick = ()=>removeDocument(i)
  }
};

function removeDocument(i){
  const upload = document.querySelector('#inputDocs');
  const divUpload = document.querySelector('#novoDocumento');
  const attachments = divUpload.querySelectorAll('.row'); // obtém todos os elementos .row dentro do divUp1

  let files = upload.files;
  const dt = new DataTransfer();
  for (let c = 0; c < files.length; c++) {
    console.log('++++++++++',files[c])
    if (c !== i) {
      dt.items.add(files[c]);
  }
}

  const newFiles = dt.files;
  console.log(newFiles[0])
  upload.files = newFiles;
  console.log(upload.files[0])
  // Remove o elemento no índice i da lista de anexos
  console.log('Antes de remover: '+ i)
  fileList.items.remove(i)
  attachments[i].remove();
  const newDivUpload = document.querySelector('#novoDocumento');
  const icon = newDivUpload.querySelectorAll('i');
  const attach = newDivUpload.querySelectorAll('.row');
  console.log('len: '+ icon.length)
  for(let cont = 0; cont < icon.length; cont++){
    console.log(cont)
    icon[cont].onclick = ()=>removeDocument(cont)
  }

}

    function removeAttachment(){
            const upload = document.querySelector('#drop-file');
            const message = document.querySelector('.file-message');
            const attachments = document.querySelector('#arquivosAdd'); // obtém todos os elementos .row dentro do divUp1
            if(upload){
                upload.value= ''
                attachments.remove();
                message.textContent = 'ou arraste e solte ele aqui'
	    }
    }

    const handleFileUpload = (event) => {
        let file = event.target.files;
        const attachments = document.querySelector('#arquivosAdd');
        if(attachments){
            attachments.remove()
        }
        const novos = document.querySelector('#novoAnexo');
        const rowDiv = document.createElement('div');
        rowDiv.className = 'row';
        rowDiv.id = 'arquivosAdd'
      
        const col7Div = document.createElement('div');
        col7Div.className = 'col-12';
        
        const label = document.createElement('label');
        label.textContent = `${file[0].name}`
        const spanUpload = document.createElement('span');
        spanUpload.className = 'hidden-xs'
        const iconUp = document.createElement('i');
        iconUp.className = 'fa fa-minus-circle ml-3 btn-remove-anexo text-danger'
        iconUp.style.fontSize = '1.25em'
        iconUp.style.position = 'relative'
        iconUp.style.top = '.15em'
        iconUp.onclick = ()=>removeAttachment()
        spanUpload.appendChild(iconUp)
        col7Div.appendChild(label);
        col7Div.appendChild(spanUpload);
        rowDiv.appendChild(col7Div);
        
        //divUpload.insertBefore(rowDiv, newAtt)
        novos.appendChild(rowDiv)
      
      };

      function edit(n){
          setResponsePaymentId(n)
      }  
    
function atualizarTitulo(titulo){
    if (titulo < 1 || titulo > 7) { return; }
    switch(titulo){
        case 1: document.querySelector('#titulo').textContent = "Dados da Requisição";
          break;
        case 2: document.querySelector('#titulo').textContent = "Anexos Adicionados";
          break;
        case 3: document.querySelector('#titulo').textContent = "Réu";
          break;
        case 4: document.querySelector('#titulo').textContent = "Autores";
          break;
        case 5: document.querySelector('#titulo').textContent = "Advogados";
          break;
        case 6: document.querySelector('#titulo').textContent = "Pagamentos";
          break;
        case 7: document.querySelector('#titulo').textContent = "Status";
          break;
	case 8: document.querySelector('#titulo').textContent = "Documentos";
	  break;
        default:
            console.log('Erro!')
    }
}

const handleSubmitFile = async (event) => {
    event.preventDefault();
    const arquivoSelecionado = document.querySelector('#drop-file').files[0];

    if (!arquivoSelecionado) {
      alert('Selecione um arquivo antes de enviar.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('upload', arquivoSelecionado);

      const response = await axios.post(`/api/novo/comprovante/${selectPaymentId}`, formData, {withCredentials: true, headers: {
        "Content-Type": "multipart/form-data"
      }});
      if(response.data==200){
        window.location = `/ci/pagamentos/${id}`
      }else{
	alert('Erro ao subir o anexo!');
      }

      // Lógica de tratamento em caso de sucesso
    } catch (error) {
      console.log('error: '+error)
      alert('Erro ao enviar o formulário:', error);
      // Lógica de tratamento em caso de erro
    }
  };
    return(
        <>
        
<div className="content-wrapper" style={{minHeight: 675}}>
      <section class= "content">
      <div className="container-fluid" style={{marginTop: 18}}>
       
         <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div className="row">
                 <div className="container center-element">
                 <ul class="nav nav-tabs nav-tabs-light flex-center" role="tablist">
                  <li role="presentation" className="nav-item" onClick={()=>atualizarTitulo(1)}>

                       <a href="#primeira-aba" class="nav-link active" aria-controls="primeira-aba" role="tab" data-toggle="tab" aria-expanded="true">
                       <span class="visible-xs visible-sm" title="" data-toggle="tooltip" data-placement="bottom" data-original-title="tab1">
                       </span>
                           <span class="hidden-xs hidden-sm">Descrição</span>
                       </a>
                   </li>
                   
                   <li role="presentation" class="nav-item" onClick={()=>atualizarTitulo(2)}>
                       <a href="#segunda-aba" class="nav-link" aria-controls="segunda-aba" role="tab" data-toggle="tab" aria-expanded="false">
                       <span class="visible-xs visible-sm" title="" data-toggle="tooltip" data-placement="bottom" data-original-title="tab2">
                           
                       </span>
                           <span class="hidden-xs hidden-sm">Anexo</span>
                       </a>
                   </li>

                   <li role="presentation" class="nav-item" onClick={()=>atualizarTitulo(6)}>
                       <a href="#sexta-aba" class="nav-link" aria-controls="sexta-aba" role="tab" data-toggle="tab" aria-expanded="false">
                       <span class="visible-xs visible-sm" title="" data-toggle="tooltip" data-placement="bottom" data-original-title="tab6">
                          
                       </span>
                           <span class="hidden-xs hidden-sm">Pagamentos</span>
                       </a>
                   </li>

                   <li role="presentation" class="nav-item" onClick={()=>atualizarTitulo(7)}>
                       <a href="#setima-aba" class="nav-link" aria-controls="setima-aba" role="tab" data-toggle="tab" aria-expanded="false">
                       <span class="visible-xs visible-sm" title="" data-toggle="tooltip" data-placement="bottom" data-original-title="tab7">
                          
                       </span>
                           <span class="hidden-xs hidden-sm">Workflow</span>
                       </a>
                   </li>

	           <li role="presentation" class="nav-item" onClick={()=>atualizarTitulo(8)}>
                 <a href="#oitava-aba" class="nav-link" aria-controls="oitava-aba" role="tab" data-toggle="tab" aria-expanded="false">
                 <span class="visible-xs visible-sm" title="" data-toggle="tooltip" data-placement="bottom" data-original-title="oitava-aba">
                     
                 </span>
                     <span class="hidden-xs hidden-sm">Documentos</span>
                 </a>
             </li>

                   </ul>
                  
                 </div>
                </div>
                <div className='row' style={{marginTop: -1}}>
                    <div className='col-12'>
                    <div class="card-header bg-info">
            <div class="inner text-center">
</div>  
          <h3 className="text-center" id='titulo'>Dados da Requisição</h3>

          </div> 
                    </div>
                </div>
            <div className='tab-content'>
            <div class="tab-pane fade show active" id="primeira-aba" role="tabpanel">  
	      <div className="card-body table-responsive p-0">
                <table class="table table-striped table-bordered mt-10">
                <tbody><tr>
                <td><b>Número do Processo:</b> {responseProcess.processo}</td>
                <td><b>Fórum:</b> {responseProcess.forum}</td>
            </tr>
            <tr>
                <td width="50%"><b>Data do Cadastro:</b> {dataFormato(responseProcess.data_cadastro)}</td>
                <td width="50%"><b>Convênio:</b> {responseUsuario.nome}</td>
            </tr>
            <tr>
                <td><b>Valor da Causa:</b> {responseProcess.valor_causa}</td>
                <td><b>Status:</b> {responseProcess.status}</td>
            </tr>
            <tr>
                <td><b>Periodo Inicial:</b> {dataFormato(responseProcess.data_inicio)}</td>
                <td><b>Periodo Final:</b> {dataFormato(responseProcess.data_estimada)}</td>
            </tr>
            <tr>
                <td><b>Observação:</b> {responseProcess.observacao} </td>
	    <td><b>Valor Total dos Pagamentos:</b> {responseProcess.valor_total}</td> 
            </tr>
            <tr>
                <td><b>Advogado(a) do Réu:</b> {responseProcess.advogado} </td>
	        <td><b>Réu:</b> {responseProcess.reu}</td>
            </tr>
	    <tr>
	    	<td><b>Advogado(a) do Autor:</b> {responseProcess.advAutor}</td>
	        <td><b>Autor:</b>{responseProcess.autor}</td>
	    </tr>
            </tbody>
                </table>
	    </div>
                <form action={`/api/observation/${id}`} method='post'>
          <div className="col-md-12">
          <div className="form-group" style={{marginTop: 13}}>
              <i class="fa fa-comment"></i>
              <label>Comentários</label>
              <textarea id="comentarios" class="form-control" name="observation" rows="6" placeholder="Deixe aqui seu comentário..."></textarea>
          </div>
          <div class="col-xs-12 text-center">
              <button class="btn btn-info" id="comentar" type='submit'>
                  <i class="fa fa-comment"></i>
                  Comentar
              </button>
          </div>
      </div>
      </form>
            </div>  
            <div class="tab-pane fade" id="segunda-aba" role="tabpanel">  
	      <div className="card-body table-responsive p-0">
                <table class="table table-striped table-bordered mt-10">
                <tbody><tr>
                                    </tr>
                <tr>
                    <th>Número do Processo</th>
                    <th>Data da Inserção do Anexo</th>
                    <th>Nome do Anexo</th>
                    <th>Referente</th>
                    <th>Ação</th>
                </tr>
                {
                    responseAnexo.map(dados=>(
                <tr>
                    <th>
                        {responseProcess.processo}
                    </th>
                    <th>
                        {dados.data}                      
                    </th>
                    <th>
                    <label title="Clique para visualizar o arquivo">
                    <a style={{color: 'green'}} href={`https://guias.almeidaenogueira.com.br/api/download/${dados.id}`}>
                    <i className="fa fa-cloud-download mr-3" />{dados.nome}                          </a>
                    </label>
                  </th>
                        <th>
                            {dados.referente}
                    </th>
                    </tr>))}
                    </tbody>
                </table>
	     </div>
            </div>  
            <div class="tab-pane fade" id="terceira-aba" role="tabpanel">  
	      <div className="card-body table-responsive p-0">
                <table class="table table-striped table-bordered mt-10">
                <tbody><tr>
                                    </tr>
                <tr>
                    <th>Nome</th>
                    <th>Telefone</th>
                    <th>CPF/CNPJ</th>
                    <th>Ação</th>
                </tr>
                {
                    responseReu.map(reu=>(
                <tr>
                    <th>
                        {reu.nome}
                    </th>
                    <th>
                            {reu.telefone}                       
                    </th>
                        <th>
                            {reu.cpf_cnpj}                        
                        </th>
                        <th>
                    </th>
                    </tr>
                    ))}
                    </tbody>
                </table>
	      </div>
            </div>
            <div class="tab-pane fade" id="quarta-aba" role="tabpanel">  
	      <div className="card-body table-responsive p-0">
                <table class="table table-striped table-bordered mt-10">
                <tbody><tr>
                                    </tr>
                <tr>
                    <th>Nome</th>
                    <th>Registro OAB</th>
                    <th>Telefone</th>
                    <th>CPF/CNPJ</th>
                    <th>Ação</th>
                </tr>

                {
                    responseAutor.map(autor=>(
                    <tr>
                    <th>
                        {autor.nome}
                    </th>
                    <th>

                    </th>
                    <th>
                            {autor.telefone}                        
                    </th>
                        <th>
                            {autor.cpf_cnpj}                        
                        </th>
                        <th>
                    </th>
                    </tr>))}
                    </tbody>
                </table>
	      </div>
            </div>
            <div class="tab-pane fade" id="quinta-aba" role="tabpanel">  
	      <div className="card-body table-responsive p-0"> 
                <table class="table table-striped table-bordered mt-10">
                <tbody><tr>
                                    </tr>
                <tr>
                <th>Nome</th>
                    <th>Registro OAB</th>
                    <th>Telefone</th>
                    <th>CPF/CNPJ</th>
                    <th>Ação</th>
                </tr>
                {
                    responseAdvogado.map(adv=>(
                    <tr>
                <th>
                        {adv.nome}
                    </th>
                    <th>

                    </th>
                    <th>
                            {adv.telefone}                        
                    </th>
                        <th>
                            {adv.cpf_cnpj}                        
                        </th>
                        <th>
                    </th>
                    </tr>))}
                    </tbody>
                </table>
	     </div>
            </div> 
            <div class="tab-pane fade" id="sexta-aba" role="tabpanel">  
              <div className="card-body table-responsive p-0">
                <table class="table table-striped table-bordered mt-10">
                <tbody><tr>                        
                                            </tr>
                    <tr>
                        <th>Status</th>
                        <th>Download</th>
                        <th>Tipo</th>
                        <th>Descrição</th>
                        <th>Valor</th>
                        <th>Data de Pagamento</th>
                        <th>Informações</th>
                        <th>Ação</th>
                    </tr>
                                        {responsePagamento.map(pagamento=>(<tr>
                        <th>
                            <i class="fas fa-money-bill-wave bg-green center-element" title={`${pagamento.status}`} id="money"> </i>                        </th>
                        <th>
                            <a style={{color: 'green'}} href={`https://guias.almeidaenogueira.com.br/api/download/pagamento/${pagamento.id}`}>
                            <i className="fa fa-cloud-download mr-3" />{pagamento.anexo}                          </a> 
                        </th><th>
                            {pagamento.tipo}                        </th>
                        <th>
                            {pagamento.descricao}                         </th>
                        <th>
                            {pagamento.valor}                        </th>
                        <th>
                            {dataFormato(pagamento.data_pagamento)}                        </th>
                        <th>
                            {pagamento.informacoes}                        </th>
                        <th>
                        <button type='button' className='btn btn-success' title='Upload do comprovante' data-toggle="modal" data-target="#uploadModal" onClick={()=>edit(pagamento.id)}><i className='fas fa-file-upload'></i></button>                        </th>
                    </tr>))}
                    
                                        
                    <tr>
                        <th>Total</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{money()}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </tbody>
                </table>
                </div>
            </div>   
            <div class="tab-pane fade" id="setima-aba" role="tabpanel">  
                <table class="table table-striped table-bordered mt-10">
                <tbody><tr>
                                    </tr>
                <tr>
                    <th>DATA</th>
                    <th>RESPONSÁVEL</th>
                    <th>STATUS</th>
                    
                </tr>
                <tr>
              <th>
                  {responseWorkflow? responseWorkflow.data: null}
              </th>
              <th>
                  {responseWorkflow? responseWorkflow.responsavel: null}
              </th>
              <th>
                  {responseWorkflow? responseWorkflow.status: null}                       
              </th>
              </tr>
                    </tbody>
                </table>
            </div>

	    <div class="tab-pane fade" id="oitava-aba" role="tabpanel">
        <div className="card-body table-responsive p-0">
          <table class="table table-striped table-bordered mt-10">
          <tbody><tr>
                              </tr>
          <tr>
              <th>Número do Arquivo</th>
              <th>Data</th>
          </tr>

          {responseDocs.map(dados=>(<tr>
     
                  <th>
                  <label title="Clique para baixar o arquivo">
<a style={{color: 'green'}} href={`https://guias.almeidaenogueira.com.br/api/download/documento/${dados.id}`}>
<i className="fa fa-cloud-download mr-3" />{dados.nome}                          </a>
</label>
                  </th>
                  <th>
                    {dataFormato(dados.data)}
              </th>
              </tr>))}
              </tbody>
          </table>
	</div>
	    <form action={`api/novo/documento/${id}`} method="post" enctype="multipart/form-data">
	      <div className='row' id='documentoUpload' style={{visibility: 'visible', display: 'block'}}>
     <div className='col-12 center-element p-2'>
    <label>
        Suba aqui seu comprovante:
    </label>
    </div>
    </div>
    <div className="row">
    <div className="col-12 center-element p-2">
   <div className="file-drop-area">
  <span className="choose-file-button">Escolha o arquivo</span>
  <span className="file-message">ou arraste e solte ele aqui</span>
  <input name='upload' id="inputDocs" className="file-input" type="file" onChange={handleDocsFile} />
</div>
    </div>
    </div>
    
    <div className='col-12' id='novoDocumento'>

    </div>

    <div className='row'>
      <div className='col-12 center-element p-2'>
        <button type='submit' className='btn btn-primary'>Salvar</button>
      </div>
    </div>
	    </form>
	</div>

            </div>

            <div class="box-header">
        <h4 class="box-title" style={{marginTop: 18}}>
        <i class="fa fa-clock"></i>
            Histórico Geral
        </h4>
        <div className="container bootstrap snippets bootdeys center-element" style={{marginTop: 30}}>
  <div className="col-md-9">
    <div className="timeline-centered timeline-sm">
    {responseTimeLine.map((dados, index)=>(
    
    <article className={`timeline-entry ${index % 2 === 0 ? '' : 'left-aligned'}`}>
  <div className="timeline-entry-inner">
    <time className="timeline-time"><span>{dados.hora}</span><span>{dados.data}</span></time>
    <div className={`timeline-icon ${dados.background}`}><i className={`${dados.icon}`} /></div>
    <div className={`timeline-label ${dados.background}`}><h4 className="timeline-title">{dados.usuario}</h4>
      <p>{dados.status}</p></div>
  </div>
</article>))}
    </div>
  </div>
</div>

    </div>
              </div>
            </div>
          </div>
      </div>
      </div>
      </section>
      <div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Comprovante</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form action={`/api/novo/comprovante/${selectPaymentId}`} method="post" enctype="multipart/form-data">
<div class="modal-body">

 <div className='row' id='pagamentoAnexo' style={{visibility: 'visible', display: 'block'}}>
     <div className='col-12'>
    <label>
        Suba aqui seu comprovante:
    </label>
   <div className="file-drop-area">
  <span className="choose-file-button">Escolha o arquivo</span>
  <span className="file-message">ou arraste e solte ele aqui</span>
  <input name='upload' id="drop-file" className="file-input" type="file" onChange={handleFileUpload} />
</div>
    </div>
    
    <div className='col-12' id='novoAnexo'>

    </div>
    </div></div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
  <button type="submit" class="btn btn-primary">Salvar</button>
</div>
</form>
</div>
</div>
</div>
      </div>
      
        
        </>
    )
}

export default Cliente

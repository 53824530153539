import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import AppAdmin from '../appMenu/appAdmin';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import $ from 'jquery';

function Comprovante(){

  const token = Cookies.get('ANers');
  const [authenticated, setAuthenticated] = useState(false); // variável de estado para autenticação
  const [responseCliente, setResponseCliente] = useState(false); // variável de estado para guardar os cliente
  const [responseNome, setResponseNome] = useState(false); // variável de estado para guardar os nomes dos comprovantes
  const [responseDisable, setResponseDisable] = useState(false); // variável de estado para desabilitar o campo cliente
  const [responseProcess, setProcess] = useState(false);
  const [resp, setResp] = useState(false);
  
  useEffect(() => {
    axios.post('/api/get/client', {token}, {WithCredentials: true}).then((response) => {
        if (response.data.auth) {
          setAuthenticated(true);
          setResponseCliente(response.data.clientes)
          setResp(response.data.usuario)
          if(response.data.nivel != 'Aners'){ // caso do usuário ser um cliente específico
            setResponseDisable(true)
          }

        } else {
          window.location = '/login';
        }
      })
      .catch(() => {
        window.location = '/login';
      });
    axios.post('/api/getprocess', {token}, {WithCredentials: true}).then((response)=>{
	if(response.data.auth){
	  setProcess(response.data.resp);
	}
    }).catch(()=>{
        window.location = '/login';
    })
  }, []);

  function getNameReceipt(){ // função para pegar as opções do select "Comprovante(s)"
    const processo = document.querySelector('#processo').value
    axios.post(`/api/get/nameReceipt/`, {token, processo}, {WithCredentials: true}).then((resp)=>{
      setAuthenticated(true);
      setResponseNome(resp.data.pagamento);
    })
  }

  function formatProcesso(e) { // função para formatação do campo "Processo" (000000-000)
    const campo = e.target;
    const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    const regex = /^(\d{0,6})-?(\d{0,3})?$/;
    const partes = regex.exec(valor);
    if (partes !== null) {
      const novoValor = partes[1] + (partes[2] ? "-" + partes[2] : "");
      campo.value = novoValor;
    } else {
        campo.value = campo.value.slice(0, -1);
      }
  }

  function informacao(comprovante){ // função para criar o campo informações da tabela
    if (comprovante.nome) {
      const th = document.createElement('th');
      const nome = document.createTextNode(`Nome: ${comprovante.nome}`);
      const cpfCnpj = document.createTextNode(`CPF/CNPJ: ${comprovante.cpf}`);
      const agencia = document.createTextNode(`AGENCIA: ${comprovante.agencia}`);
      const conta = document.createTextNode(`CONTA: ${comprovante.conta}`);
      const banco = document.createTextNode(`BANCO: ${comprovante.banco}`);
    
      th.appendChild(nome);
      th.appendChild(document.createElement('br'));
      th.appendChild(cpfCnpj);
      th.appendChild(document.createElement('br'));
      th.appendChild(agencia);
      th.appendChild(document.createElement('br'));
      th.appendChild(conta);
      th.appendChild(document.createElement('br'));
      th.appendChild(banco);
    
      return th;
    } else { // caso não tenha os dados no bd, ou seja, não é um acordo
      return document.createElement('th');
    }
  }
  

  function deleteTable(){ // função para deletar a tabela em um novo clique no botão - impedir a duplicação
    const divTable = document.querySelector('#table');
    const divTableAll = divTable.querySelectorAll('*')
    for(let c=0; c<divTableAll.length; c++){
      divTableAll[c].remove()
    }
  }

  function createTable(tableElement, tabela, divTable, responseComprovante) { // função para criação do corpo da tabela
      const tbody = document.createElement('tbody');
      const trHeader = document.createElement('tr');
      const thStatus = document.createElement('th');
      thStatus.textContent = 'Status';
      const thDownload = document.createElement('th');
      thDownload.textContent = 'Download';
      const thTipo = document.createElement('th');
      thTipo.textContent = 'Tipo';
      const thDescricao = document.createElement('th');
      thDescricao.textContent = 'Descrição';
      const thValor = document.createElement('th');
      thValor.textContent = 'Valor';
      const thDataPagamento = document.createElement('th');
      thDataPagamento.textContent = 'Data de Pagamento';
      const thInformacoes = document.createElement('th');
      thInformacoes.textContent = 'Informações';
      // Até aqui é definido o "cabeçalho" - primeira linha
      trHeader.appendChild(thStatus);
      trHeader.appendChild(thDownload);
      trHeader.appendChild(thTipo);
      trHeader.appendChild(thDescricao);
      trHeader.appendChild(thValor);
      trHeader.appendChild(thDataPagamento);
      trHeader.appendChild(thInformacoes);
    
      tbody.appendChild(trHeader);

      for (let i = 0; i < responseComprovante.length; i++) { // criação das linhas de acordo com as informações do bd
        const comprovante = responseComprovante[i];
        const tr = document.createElement('tr');
        const tdStatus = document.createElement('td');
        const iStatus = document.createElement('i');
        iStatus.className = 'fas fa-money-bill-wave bg-green center-element';
        iStatus.title = comprovante.status;
        iStatus.id = 'money';
        tdStatus.appendChild(iStatus);
    
        const tdDownload = document.createElement('td');
        const aDownload = document.createElement('a');
        aDownload.style.color = 'green';
        aDownload.href = `https://guias.almeidaenogueira.com.br/api/download/pagamento/${comprovante.id}`; // link para faz
        const iDownload = document.createElement('i');
        iDownload.className = 'fa fa-cloud-download mr-3';
        aDownload.appendChild(iDownload);
        aDownload.appendChild(document.createTextNode(comprovante.anexo));
        tdDownload.appendChild(aDownload);
    
        const tdTipo = document.createElement('td');
        tdTipo.textContent = comprovante.tipo;
    
        const tdDescricao = document.createElement('td');
        tdDescricao.textContent = comprovante.descricao;
    
        const tdValor = document.createElement('td');
        tdValor.textContent = comprovante.valor;
    
        const tdDataPagamento = document.createElement('td');
        tdDataPagamento.textContent = comprovante.data_pagamento;
    
        const tdInformacoes = document.createElement('td');
        tdInformacoes.appendChild(informacao(comprovante))
        console.log(comprovante.cpf)
    
    
        tr.appendChild(tdStatus);
        tr.appendChild(tdDownload);
        tr.appendChild(tdTipo);
        tr.appendChild(tdDescricao);
        tr.appendChild(tdValor);
        tr.appendChild(tdDataPagamento);
        tr.appendChild(tdInformacoes);
    
        tbody.appendChild(tr);
      }
    
      tableElement.appendChild(tbody);
      tabela.appendChild(tableElement);
    
      divTable.appendChild(tabela);
  }

  async function getReceipt(){ // função para pegar as informações dos comprovantes e criar a tabela
    const cliente = document.querySelector('#cliente').value
    const processo = document.querySelector('#processo').value
    const comprovante = document.querySelector('#comprovante').value
    const divTable = document.querySelector('#table');
    const tabela = document.createElement('div');
    tabela.classList.add('tab-pane', 'fade', 'show', 'active');
    tabela.setAttribute('id', 'tabela');
    tabela.setAttribute('role', 'tabpanel');
    const tableElement = document.createElement('table');
    tableElement.classList.add('table', 'table-striped', 'table-bordered', 'mt-10',);
    
    await axios.post(`/api/get/Receipt/`,{token, cliente, processo, comprovante}, {WithCredentials: true}).then((resp)=>{
      const responseComprovante = resp.data.comprovante // pega todos os dados
      createTable(tableElement, tabela, divTable, responseComprovante) // cria a tabela com base nos dados obtidos
    })
    
  }

  if (!authenticated) { // condição para aparecer o spin de carregamento
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>

  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>;
  }
  
    if(resp.id == 1){
      
    return(
        <>
      <AppHeader response={resp} />
      <AppAdmin />
      <div className="content-wrapper" style={{minHeight: 675}}>
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Pesquisar comprovantes:</font></font></h1>
        </div>
        
      </div>
    </div>
  </section>
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
            <div className='row'>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="cliente">Cliente</label>
                  <select id="cliente" className="form-control  " style={{width: '100%', position: 'static !important'}} name="cliente" disabled={responseDisable}>
                      {responseCliente.map(dados=>(<option>{dados.nome}</option>))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="dt_inicio">Processo</label>
                  <div className="input-group date" id="reservationdate" data-target-input="nearest">
                    <input type="text" className="form-control" id="processo"  name="processo" onInput={formatProcesso} onChange={getNameReceipt} style={{width: '100%', position: 'static !important'}}>
                    </input>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="dt_fim">Comprovante(s)</label>
                  <div className="input-group date" id="reservationdate2" data-target-input="nearest">
                    <select id="comprovante" className="form-control  " style={{width: '100%', position: 'static !important'}} name="comprovante">
                      <option>Todos</option>
                      {responseNome !== false ? responseNome.map((dados, index) => (
                        <option key={index}>{dados.anexo}</option>
                      )) : null}
             
                    </select> 
                  </div>
                </div>
              </div>
              <div className="col-12 text-center">
                <button className="btn btn-outline-info" type="button" onClick={async () => { deleteTable(); await getReceipt(); }}
>
                  Consultar
                  <i className="fa fa-search" />
                </button>
              </div>
              <div className="card-body table-responsive p-0" id="teste">
                <div id="table"></div>
              </div>
            </div>
          </div>
           
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

      <AppFooter />
      <AppSetting />
    </>  
     
    )
    }else{

    return(
        <>
      <AppHeader response={resp} />
      <AppMenu />
      <div className="content-wrapper" style={{minHeight: 675}}>
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Pesquisar comprovantes:</font></font></h1>
        </div>
        
      </div>
    </div>
  </section>
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
            <div className='row'>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="cliente">Cliente</label>
                  <select id="cliente" className="form-control  " style={{width: '100%', position: 'static !important'}} name="cliente" disabled={responseDisable}>
                      {responseCliente.map(dados=>(<option>{dados.nome}</option>))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="dt_inicio">Processo</label>
                  <div className="input-group date" id="reservationdate" data-target-input="nearest">
                    <select className="form-control" id="processo"  name="processo" onChange={getNameReceipt} style={{width: '100%', position: 'static !important'}}>
	              <option></option>
	              {responseProcess !== false ? responseProcess.map((dados, index)=>(
                        <option key={index}>{dados.processo}</option>
		      )): ''}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="dt_fim">Comprovante(s)</label>
                  <div className="input-group date" id="reservationdate2" data-target-input="nearest">
                    <select id="comprovante" className="form-control  " style={{width: '100%', position: 'static !important'}} name="comprovante">
                      <option>Todos</option>
                      {responseNome !== false ? responseNome.map((dados, index) => (
                        <option key={index}>{dados.anexo}</option>
                      )) : null}
             
                    </select> 
                  </div>
                </div>
              </div>
              <div className="col-12 text-center">
                <button className="btn btn-outline-info" type="button" onClick={async () => { deleteTable(); await getReceipt(); }}
>
                  Consultar
                  <i className="fa fa-search" />
                </button>
              </div>
              <div className="card-body table-responsive p-0" id="teste">
                <div id="table"></div>
              </div>
            </div>
          </div>
           
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

      <AppFooter />
      <AppSetting />
    </>  
     
    )}
}

export default Comprovante

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './home/home';
import Ci from './ci/ci';
import NoMatch from './noMatch/noMatch';
import Comprovante from './comprovante/comprovante';
import Suporte from './suporte/suporte';
import GerarProcesso from './gerarProcesso/gerarProcesso';
import Perfil from './perfil/perfil';
import Login from './login/login';
import Editor from './pagamentos/editor';
import Charts from './charts/chart';
import RelatorioCliente from './relatorios/relatorioCliente';
import Controle from './ci/controle'
import Teste from './teste/teste'
import CreateUser from './createUser/createUser'
import Clientes from './clientes/clientes';
import Usuarios from './usuarios/usuarios';
import EditarUsuarios from './usuarios/editarUsuarios';

function App() {
  
  return (
    <div className="wrapper">
      <BrowserRouter>
      <Routes>
        <Route path='/' exact element={<Home />}/>
        <Route path='/ci/controle' element={<Controle />}/>
        <Route path='/ci/novo/processo' element={<GerarProcesso />}/>
        <Route path='/comprovantes'  element={<Comprovante />}/>
        <Route path='/relatorios' exact element={<RelatorioCliente />}/>
        <Route path='/suporte' exact element={<Suporte />}/>
        <Route path="*" element={<NoMatch />} />
        <Route path='/ci/pagamentos/:id' element={<Editor />}/>
        <Route path='/perfil' element={<Perfil />}/>
        <Route path='/login' element={<Login />}/>
        <Route path='/charts' element={<Charts />}/>
        <Route path='/teste' element={<Teste />}/>
	<Route path='/cadastrar/usuario' element={<CreateUser/>}/>
	<Route path='/consultar/clientes' element={<Clientes />}/>
        <Route path='/consultar/usuarios' element={<Usuarios />}/>
        <Route path='/usuario/:id' element={<EditarUsuarios />}/>
	<Route path='/appointment' element={<h1>Teste concluido</h1>}/>
	
      </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;

function AppFooter(){
    return(
       <footer className="main-footer">
  <strong>Desenvolvido por <a href="https://almeidaenogueira.com.br/">Analyticus</a>.</strong>
  <div className="float-right d-none d-sm-inline-block">
    
  </div>
</footer>

    )
}

export default AppFooter
import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import AppAdmin from '../appMenu/appAdmin';
import axios from 'axios';
import Cookies from 'js-cookie';

function RelatorioCliente(){
    const token = Cookies.get('ANers');
    const [valorPagamentos, setValorPagamentos] = useState({acordo : {
        Janeiro: 0,
        Fevereiro: 0,
        Marco: 0,
        Abril: 0,
        Maio: 0,
        Junho: 0,
        Julho: 0,
        Agosto: 0,
        Setembro: 0,
        Outubro: 0,
        Novembro: 0,
        Dezembro: 0
    },
    apolice: {
        Janeiro: 0,
        Fevereiro: 0,
        Marco: 0,
        Abril: 0,
        Maio: 0,
        Junho: 0,
        Julho: 0,
        Agosto: 0,
        Setembro: 0,
        Outubro: 0,
        Novembro: 0,
        Dezembro: 0
    },
    deposito: {
        Janeiro: 0,
        Fevereiro: 0,
        Marco: 0,
        Abril: 0,
        Maio: 0,
        Junho: 0,
        Julho: 0,
        Agosto: 0,
        Setembro: 0,
        Outubro: 0,
        Novembro: 0,
        Dezembro: 0
    },
    custas: {
        Janeiro: 0,
        Fevereiro: 0,
        Marco: 0,
        Abril: 0,
        Maio: 0,
        Junho: 0,
        Julho: 0,
        Agosto: 0,
        Setembro: 0,
        Outubro: 0,
        Novembro: 0,
        Dezembro: 0
    }
    })
    const [pagamentosStatus, setpagamentosStatus] = useState({
      realizado: 0,
      realizar: 0,
      atrasado: 0
    })
    const [authenticated, setAuthenticated] = useState(false)
    const [resp, setResp] = useState(false)

    useEffect(()=> {
      axios.post('/api/auth', {token}, {
        withcredentials: true})
	.then((response)=>{
	  if(response.data.status === 200){
	    setAuthenticated(true);
	    setResp(response.data.user)
	  }else{
	     window.location = 'login';
	  }}).catch(() =>{
	        window.location = '/login';})
    }, [])

    useEffect(() => {
        axios.post('/api/get/value/payment', { token }, {
          withCredentials: true,
        })
          .then((resp) => {
            setValorPagamentos(resp.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [token]);

      useEffect(() => {
        axios.post('/api/get/realization', { token }, {
          withCredentials: true,
        })
          .then((resp) => {

            setpagamentosStatus(resp.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [token]);
      
  //Gráfico de Barra  
  const chartContainerBar = useRef(null);
  const chartDataBar = {
    labels: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    datasets: [
      {
        label: "$ Acordo",
        data: [valorPagamentos.acordo.Janeiro, valorPagamentos.acordo.Fevereiro, valorPagamentos.acordo.Marco, valorPagamentos.acordo.Abril, valorPagamentos.acordo.Maio,  valorPagamentos.acordo.Junho, valorPagamentos.acordo.Julho, valorPagamentos.acordo.Agosto, valorPagamentos.acordo.Setembro, valorPagamentos.acordo.Outubro, valorPagamentos.acordo.Novembro, valorPagamentos.acordo.Dezembro],
        borderWidth: 1,
        backgroundColor: "#f2cc30",
      },
      {
        label: "$ Apólice",
        data: [valorPagamentos.apolice.Janeiro, valorPagamentos.apolice.Fevereiro, valorPagamentos.apolice.Marco, valorPagamentos.apolice.Abril, valorPagamentos.apolice.Maio,  valorPagamentos.apolice.Junho, valorPagamentos.apolice.Julho, valorPagamentos.apolice.Agosto, valorPagamentos.apolice.Setembro, valorPagamentos.apolice.Outubro, valorPagamentos.apolice.Novembro, valorPagamentos.apolice.Dezembro],
        borderWidth: 1,
        backgroundColor: "#b5cf48",
      },
      {
        label: "$ Custas",
        data: [valorPagamentos.custas.Janeiro, valorPagamentos.custas.Fevereiro, valorPagamentos.custas.Marco, valorPagamentos.custas.Abril, valorPagamentos.custas.Maio,  valorPagamentos.custas.Junho, valorPagamentos.custas.Julho, valorPagamentos.custas.Agosto, valorPagamentos.custas.Setembro, valorPagamentos.custas.Outubro, valorPagamentos.custas.Novembro, valorPagamentos.custas.Dezembro],
        borderWidth: 1,
        backgroundColor: "#62b0b3",
      },
      {
        label: "$ Depósito",
        data: [valorPagamentos.deposito.Janeiro, valorPagamentos.deposito.Fevereiro, valorPagamentos.deposito.Marco, valorPagamentos.deposito.Abril, valorPagamentos.deposito.Maio,  valorPagamentos.deposito.Junho, valorPagamentos.deposito.Julho, valorPagamentos.deposito.Agosto, valorPagamentos.deposito.Setembro, valorPagamentos.deposito.Outubro, valorPagamentos.deposito.Novembro, valorPagamentos.deposito.Dezembro],
        borderWidth: 1,
        backgroundColor: "#5e539f",
      },
    ],
  };

  useEffect(() => {
    if (chartContainerBar && chartContainerBar.current) {
      const newChartInstanceBar = new Chart(chartContainerBar.current, {
        type: "bar",
        data: chartDataBar,
        options: {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
      });
      return () => {
        newChartInstanceBar.destroy();
      };
    }
  }, [chartContainerBar, chartDataBar]);

  //Gráfico de Pizza
  const chartContainerPie = useRef(null);
  const chartDataPie = {
    labels: [
      "Realizado",
      "À Realizar",
      "Atrasado"
    ],
    datasets: [
      {
        label: "Total",
        data: [pagamentosStatus.realizado, pagamentosStatus.realizar, pagamentosStatus.atrasado],
        borderWidth: 1,
      }
    ],
  };

  useEffect(() => {
    if (chartContainerPie && chartContainerPie.current) {
      const newChartInstancePie = new Chart(chartContainerPie.current, {
        type: "pie",
        data: chartDataPie,
        options: {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          maintainAspectRatio: false
        },
      });
      return () => {
        newChartInstancePie.destroy();
      };
    }
  }, [chartContainerPie, chartDataPie]);

    if(!authenticated){
      return <div style={{
	display: 'flex',
        justifyContent: 'center',
	alignItems: 'center',
        height: '100vh'}}>
        <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>;
    }
  if(resp.id == 1){
	  return (
    <>
    <AppHeader response={resp}/>
    <AppAdmin />
    <div className="content-wrapper" style={{minHeight: '1345.31px'}}>
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>Relatórios</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/">Início</a></li>
            <li className="breadcrumb-item active">Relatórios</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">Pagamentos</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chart-container" style={{position: 'relative', height: '40vh', width: '100%'}}>
                <canvas ref={chartContainerBar} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">Realização de Pagamentos</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chart-container" style={{position: 'relative', height: '40vh', width: '100%'}}>
                <canvas ref={chartContainerPie} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


    <AppFooter />
    <AppSetting />
  
    </>
  );
  }else{
  return (
    <>
    <AppHeader response={resp}/>
    <AppMenu />
    <div className="content-wrapper" style={{minHeight: '1345.31px'}}>
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>Relatórios</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/">Início</a></li>
            <li className="breadcrumb-item active">Relatórios</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">Pagamentos</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chart-container" style={{position: 'relative', height: '40vh', width: '100%'}}>
                <canvas ref={chartContainerBar} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">Realização de Pagamentos</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chart-container" style={{position: 'relative', height: '40vh', width: '100%'}}>
                <canvas ref={chartContainerPie} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


    <AppFooter />
    <AppSetting />
  
    </>
  );}
       
}

export default RelatorioCliente;

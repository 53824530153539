import './Login.css'
import React, { useEffect, useState} from 'react';
import axios from 'axios';

function Login(){

  const [blockedTime, setBlockedTime] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false)

  function isErro(){
    const erro = document.querySelector("#erro")
    if(erro){
      erro.innerHTML = ''
      document.querySelector("#email").style.borderColor = 'white'
      document.querySelector("#password").style.borderColor = 'white'
    }
  }

  function formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
      
  function Relogio(props) {
    const dataExp = new Date(localStorage.getItem('tempo'))
    const dataAtual = new Date()
    
    if(props.blockedTime == 0){
      if(dataExp > dataAtual){
        let conta = Math.ceil((dataExp - dataAtual) / 1000);
        setBlockedTime(conta)
        setIsDisabled(true)
      } else {
        setIsDisabled(false)  
      }
    }

    if(props.blockedTime > 0){
      //setBlockedTime(localStorage.getItem("tempo"))
      return (
        <div id="relogio" classList="texto-branco">
          Tempo de espera: {formatTime(props.blockedTime)}
        </div>
      );
    }
  }

  useEffect(() => {
    let interval = null;
    if (blockedTime > 0) {
      interval = setInterval(() => {
        setBlockedTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [blockedTime]);

    useEffect(() => {
        document.body.style.backgroundImage = "url('https://guias.almeidaenogueira.com.br/api/get/background/background_feedback.jpg')";
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';
      }, []);
    
      async function sign(event) {
        event.preventDefault(); // Adicione esta linha para evitar o comportamento padrão do formulário
      
        try {
          const email = document.querySelector("#email").value;
          const password = document.querySelector("#password").value;
          const response = await axios.post("https://guias.almeidaenogueira.com.br/api/sign", {
            email,
            password,
          }, {
            withCredentials: true,
          });
          
          if (response.data.status === 200) {
            window.location.href = "https://guias.almeidaenogueira.com.br/";
          } else if (response.data.status === 400 || response.data.msg == undefined) {
            console.log('entrou no inválido')
            const erro = document.querySelector("#erro")
            while(erro.firstChild) {
              erro.removeChild(erro.firstChild);
            }
            setTimeout(()=>{
              document.querySelector("#email").style.borderColor = 'red'
              document.querySelector("#password").style.borderColor = 'red'
              erro.classList.add("texto-vermelho");
              let divText = document.createTextNode("Usuário e/ou senha inválidos!") // Cria o elemento de texto
              erro.appendChild(divText)
            }, 50) //Pequeno delay de criação apenas para deixar mais intuitivo
            
          } else {
            alert(response.data.msg);
            setBlockedTime(response.data.time);
            localStorage.setItem("tempo", response.data.date)
            setIsDisabled(true)
          }
        } catch (error) {
          // Altere a estrutura do seu tratamento de erro para lidar com os diferentes códigos de status HTTP
          alert(error)
          if(error.response == 'O usuário já está sendo utilizado'){
            alert('O usuário já está sendo utilizado')
          }
          if (error.response) {
            if (error.response.status === 400) {
              alert("Usuário e senha inválidos!");
              window.location.href = "https://guias.almeidaenogueira.com.br/login";
            } else {
              console.error("Erro na resposta do servidor:", error.response);
              alert(
                `Erro na resposta do servidor: ${error.response.status} ${error.response.statusText}`
              );
              window.location.href = "https://guias.almeidaenogueira.com.br/bad/request";
            }
          } else if (error.request) {
            console.error("Erro ao realizar a solicitação:", error.request);
            alert("Erro ao realizar a solicitação.");
            window.location.href = "https://guias.almeidaenogueira.com.br/bad/request";
          } else {
            console.error("Erro ao configurar a solicitação:", error.message);
            alert(`Erro ao configurar a solicitação: ${error.message}`);
            window.location.href = "https://guias.almeidaenogueira.com.br/bad/request";
          }
          
        }
      }
      
      
      
      


    return(
     <>
     <div className='box-back'>
     <div class="white-box" id="login-box">
        <div id='tela-login' className='container'>
            <div className='center-element'>
            <img id='AN-logo' src="/dist/img/pngBranco.png" alt="AN Logo"  style={{opacity: '.8'}} />
            </div>
     
            <form id="form-login" onSubmit={sign}>
                <div className='form-group'>
                <Relogio blockedTime={blockedTime} classList="text-white"/>
                <div id="erro" > 
                  {isErro()}
                </div>

                    <label className='text-white'>
                        Usuário
                    </label>
                    <input type="text" className="form-control" id="email" name='email' disabled={isDisabled} />
                </div>
                <div className='form-group'>
                    <label className='text-white'>
                        Senha
                    </label>
                    <input type="password" className="form-control" id="password" name='password' disabled={isDisabled}/>
                </div>
                <div class="col-xs-12 text-center">
                    <button type='submit' class="btn btn-success" id="atualizar" disabled={isDisabled}>Entrar</button>
                </div>
                </form>
        </div>
     </div>
     </div>
     </>
    )
}

export default Login;

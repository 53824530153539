import AppFooter from "../appFooter/appFooter";
import AppHeader from "../appHeader/appHeader";
import AppAdmin from "../appMenu/appAdmin";
import AppSetting from "../appSetting/appSetting";
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Usuarios(){
    const token = Cookies.get('ANers');
    const [authenticated, setAuthenticated] = useState(false);
    const [resp, setResp] = useState(false);
    const [resC, setResC] = useState(false);
    const [resUsers, setUsers] = useState(false);
    const[editClient, setClient] = useState(null)

useEffect(() => {
    axios.post('/api/get/all/users', null, {withCredentials: true}).then((response)=>{
        if(response.data.status == 200){
            setResp(response.data.user)
            setUsers(response.data.users);
            setResC(response.data.client);
            setAuthenticated(true);
        }
    }).catch(()=>{
        window.location = '/login';
    })
}, []);

if (!authenticated) {
  return <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  }}>
<div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
</div>;
}

    async function removeUser(id){
        try {
            const response = await axios.post(`/api/remove/user/${id}`, null, {withCredentials: true})
            if(response.data.status==200){
                window.location = '/consultar/usuarios'
            }
            else{
                alert(response.data.msg)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function setIdClient(id){
  setClient(id)
}

    return (
        <>
        <AppHeader response={resp}/>
        <AppAdmin />
        <div className="content-wrapper" style={{minHeight: 675}}>
      <section class= "content">
      <div className="container-fluid" style={{marginTop: 18}}>
         <div className="row center-element">
          <h1>
            Consultar Usuários
          </h1>
         </div>
         <div id='erros'>

         </div>
         <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
              <div class="row">
<div className="col-lg-12 col-md-12">
  <div className="white-box">

  <div className="tab-content">
  <table id="example1" className="table table-bordered table-striped dataTable dtr-inline" aria-describedby="example1_info">
          <thead>
            <tr>
            <th style={{width: 10}}>#</th>
              <th>Nome</th>
              <th>Empresa</th>
              <th style={{width: 100}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            { 
              resUsers.map((dados, index)=>(
            <tr>
              <td>{index+1}</td>
              <td>{dados.nome}</td>
              <td>{resC[index].nome}</td>
              <td><button className="btn btn-danger" data-toggle="modal" data-target="#deleteUser" onClick={()=>setIdClient(dados.id)}><i className="fa fa-trash"></i></button><a href={`/usuario/${dados.id}`}><button style={{marginLeft: 10}} className="btn btn-info"><i className="fa fa-edit"></i></button></a></td>
            </tr>
              ))}
              
          </tbody>
        </table>
  </div>
  </div>
</div>

              </div>

              </div>
            </div>
          </div>
      </div>
      </div>
      </section>
      </div>	
	 <div class="modal fade" id="deleteUser" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-sm" role="document">
<div class="modal-content">
<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Deseja mesmo excluir este usuário?</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
<div className="row">
      <div className="col-6 center-element">
        <button className="btn btn-danger" onClick={()=>removeUser(editClient)}>
          Sim
        </button>
      </div>
      <div className="col-6 center-element">
        <button className="btn btn-primary">
          Não
          </button>
      
      </div>
    </div>
</div>
</div>
</div>
</div>
	<select className="select2" style={{display: 'none'}} />
        <AppFooter />
        <AppSetting />
       	</>
    )
}

export default Usuarios



function AppHeader({response}){

    return(
<nav className="main-header navbar navbar-expand navbar-white navbar-light">
  <div className="container-fluid">
    
    <div className="collapse navbar-collapse" id="main_nav">
    <ul className="navbar-nav">
  <li className="nav-item">
    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
  </li>
  <li className="nav-item d-none d-sm-inline-block">
  <a href="#" className="nav-link">Início</a>
</li>

</ul>

    <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> {response.nome}</a>
          <ul className="dropdown-menu fade-up">
            <li>
              <a className="dropdown-item" href="/perfil">
                
              <i className="fa fa-user-circle" aria-hidden="true"></i>
              Perfil
              </a>
              </li>
              <div className="dropdown-divider" />

            <li>
              <a className="dropdown-item" href="https://guias.almeidaenogueira.com.br/api/logout"> 
              <i className="fas fa-sign-out-alt" />
              Sair </a></li>
          </ul>
        </li>
   </ul>
      
    </div> {/* navbar-collapse.// */}
  </div> {/* container-fluid.// */}
</nav>

)
}

export default AppHeader;

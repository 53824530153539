import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import AppAdmin from '../appMenu/appAdmin';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';


function Perfil(){

  const token = Cookies.get('ANers');
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({nome: null, email: null, nome_fantasia: null, telefone1: null, telefone2: null, endereço: null, numero: null, complemento: null, uf: null, cidade: null, cep: null, cpf_cnpj: null})
  const [newUser, setNewUser] = useState({nome: null, email: null, nome_fantasia: null, telefone1: null, telefone2: null, endereço: null, numero: null, complemento: null, uf: null, cidade: null, cep: null, cpf_cnpj: null})
  useEffect(() => {
    axios.post('/api/get/user', {token}, {
      withCredentials: true,
    }).then((resp)=>{
      if(resp.data.status === 200){
        setAuthenticated(true)
        setUser(resp.data.user)
        setNewUser(resp.data.user)
        console.log(resp.data)
      }
    })
  }, [authenticated])

  if (!authenticated) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>;
  }

  function updateUser(){
    let razao = document.querySelector('#nome').value
    let fantasia = document.querySelector('#nome_fantasia').value
    let telefone1 = document.querySelector('#telefone1').value
    let telefone2 = document.querySelector('#telefone2').value
    let email = document.querySelector('#email').value
    let endereco = document.querySelector('#endereço').value
    let numero = document.querySelector('#numero').value
    let complemento = document.querySelector('#complemento').value
    let uf = document.querySelector('#uf').value
    let cidade = document.querySelector('#cidade').value
    let cep = document.querySelector('#cep').value
    axios.post('/api/set/user', {token, razao, fantasia, telefone1, telefone2, email, endereco, numero, complemento, uf, cidade, cep}, {
      withCredentials: true,
    }).then((resp)=>{
      if(resp.data.status==200){
        window.location = '/perfil'
      }else{
        alert(resp.data.erro)
      }
    })
  }

  function loadForm(event){
      const { name, value } = event.target;
           if(name=='telefone1'|| name=='telefone2'){
        console.log('dentro!')
        // Remove tudo que não for número
        const newValue = value.replace(/\D/g, '');

        // Formata o telefone
        let formattedValue = '';
        if (newValue.length <= 10) {
          // Formatação para telefone de 10 dígitos (XXXXXXXXXX)
          formattedValue = newValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else {
      // Formatação para telefone de 11 dígitos (XXXXXXXXXXX)
      formattedValue = newValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }

    setNewUser(prevState => ({
      ...prevState,
      [name]: formattedValue
    }))
  

      }else{
      setNewUser(prevState => ({
        ...prevState,
        [name]: value
      }));}
      
  }

  function setPassword(){
    let atual = document.querySelector('#atual').value
    let password = document.querySelector('#password').value
    let confirmar = document.querySelector('#confirmar').value
    if(password==confirmar){
    axios.post('/api/set/password', {token, password, atual}, {
      withCredentials: true,
    }).then((resp)=>{
      if(resp.data.status==200){
        window.location = '/perfil'
      }else{
        alert(resp.data.erro)
      }
    })
  }else{
    if(password==""){
      alert("Por favor preencha o campo 'Senha Atual'")

    }else{
    alert('Você digitou a confirmação da senha errado!')}
  }
  }

    if(user.id == 1){
      
    return(
        <>
        <AppHeader response={user}/>
        <AppAdmin />
            
<div className="content-wrapper" style={{minHeight: 675}}>
      <section class= "content">
      <div className="container-fluid" style={{marginTop: 18}}>
       
         <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
              <div class="row">
              <div className="col-lg-4 col-md-12">
  <div className="white-box">
    <div className="user-btm-box p-2">
      {/* .row */}
      <div className="row text-center m-0">
        <div className="col-12"><strong>CPF</strong>
          <p>{user.cpf_cnpj}</p>
        </div>
      </div>
      {/* /.row */}
      <div className="row text-center m-0">
        <div className="col-md-6 b-r"><strong>Nome</strong>
          <p>{user.nome}</p>
        </div>
        <div className="col-md-6"><strong>Departamento</strong>
          <p>{user.nome_fantasia}</p>
        </div>
      </div>
      {/* /.row */}
      <hr />
      {/* .row */}
      <div className="row text-center m-t-10">
        <div className="col-md-12 b-r"><strong>Email</strong>
          <p>{user.email}</p>
        </div>
      </div>
      <div className="row text-center m-t-10">
        <div className="col-md-12 b-r"><strong>Telefone(s)</strong>
          <p>{user.telefone1}</p>
        </div>
      </div>
      {/* /.row */}
      <hr />
      {/* .row */}
      <div className="row text-center m-t-10">
        <div className="col-md-12"><strong>Endereço</strong>
          <p> 
            {user.endereço},{user.numero} - {user.cep}<br />{user.cidade} - {user.uf}<br />                      </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="col-lg-8 col-md-12">
  <div className="white-box">
  <ul class="nav nav-tabs nav-tabs-light flex-center center-element" role="tablist">
                  <li role="presentation" className="nav-item">

                       <a href="#primeira-aba" class="nav-link active" aria-controls="primeira-aba" role="tab" data-toggle="tab" aria-expanded="true">
                       <span class="visible-xs visible-sm" title="" data-toggle="tooltip" data-placement="bottom" data-original-title="tab1">
                       </span>
                           <span class="hidden-xs hidden-sm"><i class="fa fa-user-circle" aria-hidden="true"></i>Editar Perfil</span>
                       </a>
                   </li>
                   
                   <li role="presentation" class="nav-item">
                       <a href="#segunda-aba" class="nav-link" aria-controls="segunda-aba" role="tab" data-toggle="tab" aria-expanded="false">
                       <span class="visible-xs visible-sm" title="" data-toggle="tooltip" data-placement="bottom" data-original-title="tab2">
                           
                       </span>
                           <span class="hidden-xs hidden-sm"><i class="fa fa-lock" aria-hidden="true"></i>Alterar Senha</span>
                       </a>
                   </li>
                   </ul>
  <div className="tab-content">
  <div class="tab-pane fade show active" id="primeira-aba" role="tabpanel">  
             
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label className="required">Nome</label>
                  <input type="text" name='nome' className="form-control" id="nome" value={newUser.nome} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-6">
                <div className="form-group">
                  <label>Nome Fantasia</label>
                  <input type="text" name='nome_fantasia' className="form-control" id="nome_fantasia" value={newUser.nome_fantasia} onChange={loadForm}/>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label className="required">Telefone 1</label>
                  <input type="text" className="form-control" maxLength={15} name="telefone1" id="telefone1" value={newUser.telefone1} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-6">
                <div className="form-group">
                  <label>Telefone 2</label>
                  <input type="text" className="form-control" maxLength={15} name="telefone2" id="telefone2" value={newUser.telefone2} onChange={loadForm}/>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-12">
                <div className="form-group">
                  <label className="required">Email</label>
                  <input type="email" className="form-control" name="email" id="email" placeholder="meuemail@email.com" value={newUser.email} onChange={loadForm}/>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label>Endereço</label>
                  <input type="text" className="form-control" name="endereço" id="endereço" value={newUser.endereço} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-2">
                <div className="form-group">
                  <label>N°</label>
                  <input type="text" className="form-control" name="numero" id="numero" value={newUser.numero} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-4">
                <div className="form-group">
                  <label>Complemento</label>
                  <input type="text" className="form-control" name='complemento' id="complemento" value={newUser.complemento} onChange={loadForm}/>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label>U.F.</label>
                  <input type="text" className="form-control" name='uf' id="uf" value={newUser.uf} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-2">
                <div className="form-group">
                  <label>Cidade</label>
                  <input type="text" className="form-control" name='cidade' id="cidade" value={newUser.cidade} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-4">
                <div className="form-group">
                  <label>CEP</label>
                  <input type="text" className="form-control" name='cep' id="cep" value={newUser.cep} onChange={loadForm}/>
                  </div>
                </div>
                </div>
                <div className="col-md-12">
                
                <div class="col-xs-12 text-center">
                    <button class="btn btn-success" id="atualizar" onClick={updateUser}>
                        Atualizar Perfil
                    </button>
                </div>
            </div>
           
            </div>  
            <div class="tab-pane fade" id="segunda-aba" role="tabpanel">  
              
                <div className='row'>
                  <div className="col-12">
                    <div className="form-group">
                    <label className="required">
                      Senha Atual
                    </label>
                    <input type="password" className="form-control" id="atual" placeholder="******" />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12">
                    <div className="form-group">
                    <label className="required">
                      Nova Senha
                    </label>
                    <input type="password" className="form-control" id="password" placeholder="******" />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12">
                    <div className="form-group">
                    <label className="required">
                      Confirmar Nova Senha
                    </label>
                    <input type="password" className="form-control" id="confirmar" placeholder="******" />
                    </div>
                  </div>
                </div>
                <div className="row">
                <div class="col-md-12">
                  <div class="col-xs-12 text-center">
                    <button class="btn btn-success" id="atualizar" onClick={setPassword}>Atualizar Senha</button>
                  </div>
                  </div>
                </div>
            </div> 
  </div>
  </div>
</div>

              </div>

              </div>
            </div>
          </div>
      </div>
      </div>
      </section>
      </div>
        <AppFooter />
        <AppSetting />
        </>
    )
    }
    else{
	
    return(
        <>
        <AppHeader response={user} />
        <AppMenu />
            
<div className="content-wrapper" style={{minHeight: 675}}>
      <section class= "content">
      <div className="container-fluid" style={{marginTop: 18}}>
       
         <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
              <div class="row">
              <div className="col-lg-4 col-md-12">
  <div className="white-box">
    <div className="user-btm-box p-2">
      {/* .row */}
      <div className="row text-center m-0">
        <div className="col-12"><strong>CPF</strong>
          <p>{user.cpf_cnpj}</p>
        </div>
      </div>
      {/* /.row */}
      <div className="row text-center m-0">
        <div className="col-md-6 b-r"><strong>Nome</strong>
          <p>{user.nome}</p>
        </div>
        <div className="col-md-6"><strong>Departamento</strong>
          <p>{user.nome_fantasia}</p>
        </div>
      </div>
      {/* /.row */}
      <hr />
      {/* .row */}
      <div className="row text-center m-t-10">
        <div className="col-md-12 b-r"><strong>Email</strong>
          <p>{user.email}</p>
        </div>
      </div>
      <div className="row text-center m-t-10">
        <div className="col-md-12 b-r"><strong>Telefone(s)</strong>
          <p>{user.telefone1}</p>
        </div>
      </div>
      {/* /.row */}
      <hr />
      {/* .row */}
      <div className="row text-center m-t-10">
        <div className="col-md-12"><strong>Endereço</strong>
          <p> 
            {user.endereço},{user.numero} - {user.cep}<br />{user.cidade} - {user.uf}<br />                      </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="col-lg-8 col-md-12">
  <div className="white-box">
  <ul class="nav nav-tabs nav-tabs-light flex-center center-element" role="tablist">
                  <li role="presentation" className="nav-item">

                       <a href="#primeira-aba" class="nav-link active" aria-controls="primeira-aba" role="tab" data-toggle="tab" aria-expanded="true">
                       <span class="visible-xs visible-sm" title="" data-toggle="tooltip" data-placement="bottom" data-original-title="tab1">
                       </span>
                           <span class="hidden-xs hidden-sm"><i class="fa fa-user-circle" aria-hidden="true"></i>Editar Perfil</span>
                       </a>
                   </li>
                   
                   <li role="presentation" class="nav-item">
                       <a href="#segunda-aba" class="nav-link" aria-controls="segunda-aba" role="tab" data-toggle="tab" aria-expanded="false">
                       <span class="visible-xs visible-sm" title="" data-toggle="tooltip" data-placement="bottom" data-original-title="tab2">
                           
                       </span>
                           <span class="hidden-xs hidden-sm"><i class="fa fa-lock" aria-hidden="true"></i>Alterar Senha</span>
                       </a>
                   </li>
                   </ul>
  <div className="tab-content">
  <div class="tab-pane fade show active" id="primeira-aba" role="tabpanel">  
             
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label className="required">Nome</label>
                  <input type="text" name='nome' className="form-control" id="nome" value={newUser.nome} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-6">
                <div className="form-group">
                  <label>Departamento</label>
                  <input type="text" name='nome_fantasia' className="form-control" id="nome_fantasia" value={newUser.nome_fantasia} onChange={loadForm}/>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label className="required">Telefone 1</label>
                  <input type="text" className="form-control" maxLength={15} name="telefone1" id="telefone1" value={newUser.telefone1} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-6">
                <div className="form-group">
                  <label>Telefone 2</label>
                  <input type="text" className="form-control" maxLength={15} name="telefone2" id="telefone2" value={newUser.telefone2} onChange={loadForm}/>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-12">
                <div className="form-group">
                  <label className="required">Email</label>
                  <input type="email" className="form-control" name="email" id="email" placeholder="meuemail@email.com" value={newUser.email} onChange={loadForm}/>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label>Endereço</label>
                  <input type="text" className="form-control" name="endereço" id="endereço" value={newUser.endereço} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-2">
                <div className="form-group">
                  <label>N°</label>
                  <input type="text" className="form-control" name="numero" id="numero" value={newUser.numero} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-4">
                <div className="form-group">
                  <label>Complemento</label>
                  <input type="text" className="form-control" name='complemento' id="complemento" value={newUser.complemento} onChange={loadForm}/>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <label>U.F.</label>
                  <input type="text" className="form-control" name='uf' id="uf" value={newUser.uf} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-2">
                <div className="form-group">
                  <label>Cidade</label>
                  <input type="text" className="form-control" name='cidade' id="cidade" value={newUser.cidade} onChange={loadForm}/>
                  </div>
                </div>
                <div className="col-4">
                <div className="form-group">
                  <label>CEP</label>
                  <input type="text" className="form-control" name='cep' id="cep" value={newUser.cep} onChange={loadForm}/>
                  </div>
                </div>
                </div>
                <div className="col-md-12">
                
                <div class="col-xs-12 text-center">
                    <button class="btn btn-success" id="atualizar" onClick={updateUser}>
                        Atualizar Perfil
                    </button>
                </div>
            </div>
           
            </div>  
            <div class="tab-pane fade" id="segunda-aba" role="tabpanel">  
              
                <div className='row'>
                  <div className="col-12">
                    <div className="form-group">
                    <label>
                      Senha Atual
                    </label>
                    <input type="password" className="form-control" id="atual" placeholder="******" />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12">
                    <div className="form-group">
                    <label>
                      Nova Senha
                    </label>
                    <input type="password" className="form-control" id="password" placeholder="******" />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12">
                    <div className="form-group">
                    <label>
                      Confirmar Nova Senha
                    </label>
                    <input type="password" className="form-control" id="confirmar" placeholder="******" />
                    </div>
                  </div>
                </div>
                <div className="row">
                <div class="col-md-12">
                  <div class="col-xs-12 text-center">
                    <button class="btn btn-success" id="atualizar" onClick={setPassword}>Atualizar Senha</button>
                  </div>
                  </div>
                </div>
            </div> 
  </div>
  </div>
</div>

              </div>

              </div>
            </div>
          </div>
      </div>
      </div>
      </section>
      </div>
        <AppFooter />
        <AppSetting />
        </>
    )}
}

export default Perfil;

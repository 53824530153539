function AppDashboard(){
    return(
      <div className="content-wrapper" style={{minHeight: 675}}>
  <section className="content" style={{marginTop: 25}}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3 col-6">
          <div className="small-box bg-info">
            <div className="inner">
            <img src="/dist/img/martelo.png"></img>
            </div>
            
            <a href="/ci/controle" className="small-box-footer">Processos <i className="fas fa-arrow-circle-right" /></a>
          </div>
        </div>
      </div>
      
    </div>
  </section>
</div>

    )
}

export default AppDashboard

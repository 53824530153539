import { useEffect } from "react";
import { useState } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

function AppMenu(){
    const [user, setUser] = useState({nome: null, email: null})
    const token = Cookies.get('ANers');
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
      axios.post('/api/get/user', {token}, {
        withCredentials: true,
      }).then((resp)=>{
        if(resp.data.status === 200){
          setAuthenticated(true)
          setUser(resp.data.user)
          console.log(resp.data)
        }
      })
    }, [authenticated])

    if (!authenticated) {
      return <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>;
    }

    

    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{background: '#17a2b8'}}>

  <div>
    <a href="#" className="brand-link">
      <img src="/dist/img/pngBranco.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
      <span className="brand-text text-white">AN Documentos</span>
    </a>
  </div>
  <div className="sidebar">
    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
      <div className="info">
        <a href="/perfil" className="text-white">{user.nome}</a>
      </div>
    </div>
    
   {/* Sidebar Menu */}
<nav className="mt-2">
  <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
    {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
    
        <li className="nav-item">
          <a href="/" className="nav-link">
            <i className="fa fa-home nav-icon text-white" />
            <p className="text-white">Início</p>
          </a>
        </li>
        
        <li className="nav-item">
          <a href="/comprovantes" className="nav-link">
            <i className="nav-icon fas fa-copy text-white" />
            <p className="text-white">Comprovantes</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="/relatorios" className="nav-link">
            <i className="nav-icon fas fa-chart-pie text-white" />
            <p className="text-white">Relatórios</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="/suporte" className="nav-link">
            <i className="fa fa-users nav-icon text-white" />
            <p className="text-white">Suporte</p>
          </a>
        </li>
    
  </ul>
</nav>

  </div>
</aside>

      )
}

export default AppMenu;

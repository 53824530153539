import AppHeader from '../appHeader/appHeader';
import AppMenu from '../appMenu/appMenu';
import AppAdmin from '../appMenu/appAdmin';
import AppFooter from '../appFooter/appFooter';
import AppSetting from '../appSetting/appSetting';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

function Suporte(){

  const token = Cookies.get('ANers');
  const [authenticated, setAuthenticated] = useState(false);
  const [resp, setResp] = useState(false)

  useEffect(() => {
    axios.post('/api/auth', {token}, {
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.status === 200) {
          setAuthenticated(true);
	  setResp(response.data.user)
        } else {
          window.location = '/login';
        }
      })
      .catch(() => {
        window.location = '/login';
      });
  }, []);

  if (!authenticated) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>;
  }

   if(resp.id == 1){
    
    return(
    <>
     <AppHeader response={resp}/>
     <AppAdmin />
     <div className="content-wrapper" style={{minHeight: '1604.44px'}}>
       <section className="content-header">
         <div className="container-fluid">
           <div className="row mb-2">
             <div className="col-sm-6">
               <h1><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Contatos</font></font></h1>
             </div>
            
           </div>
         </div>
      </section>
<section className="content">
<div className="card card-solid">
  <div className="card-body pb-0">
    <div className="row">
      <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
        <div className="card bg-light d-flex flex-fill">
          <div className="card-header text-muted border-bottom-0"><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>
                Departamento Trabalhista
              </font></font></div>
          <div className="card-body pt-0">
            <div className="row">
              <div className="col-7">
                <h2 className="lead"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Arthur Sanches</font></font></b></h2>
                <p className="text-muted text-sm"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Sobre:</font></font></b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}> ​​Sócio / Advogado Gestor da Trabalhista</font></font></p>
                <ul className="ml-4 mb-0 fa-ul text-muted">
                  <li className="small"><span className="fa-li"><i className="fas fa-lg fa-building" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Endereço:..</font></font></li>
                  <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Telefone: (14) 99788-8112</font></font></li>
                </ul>
              </div>
              <div className="col-5 text-center">
                <img src="../../dist/img/arthur.png" alt="avatar de usuário" className="img-circle img-fluid" />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="text-right">
                         </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
        <div className="card bg-light d-flex flex-fill">
          <div className="card-header text-muted border-bottom-0"><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>
                Departamento Empresarial
              </font></font></div>
          <div className="card-body pt-0">
            <div className="row">
              <div className="col-7">
                <h2 className="lead"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Carlos Eduardo Ribeiro Pugliezi</font></font></b></h2>
                <p className="text-muted text-sm"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Sobre:</font></font></b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}> ​Advogado Empresarial</font></font></p>
                <ul className="ml-4 mb-0 fa-ul text-muted">
                  <li className="small"><span className="fa-li"><i className="fas fa-lg fa-building" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Endereço: Marechal Rondon, 101, AP 103</font></font></li>
                  <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Telefone: (18) 99729-8468</font></font></li>
                </ul>
              </div>
              <div className="col-5 text-center">
                <img src="../../dist/img/cadu.png" alt="avatar de usuário" className="img-circle img-fluid" />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="text-right">
                          </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
        <div className="card bg-light d-flex flex-fill">
          <div className="card-header text-muted border-bottom-0"><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>
                Departamento de Tecnologia
              </font></font></div>
          <div className="card-body pt-0">
            <div className="row">
              <div className="col-7">
                <h2 className="lead"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Jonathan Novaes</font></font></b></h2>
                <p className="text-muted text-sm"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Sobre:</font></font></b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}> ​​Desenvolvedor Full Stack</font></font></p>
                <ul className="ml-4 mb-0 fa-ul text-muted">
                  <li className="small"><span className="fa-li"><i className="fas fa-lg fa-building" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Endereço: Reverendo João Batista Ribeiro Neto, 30, PQ. Universitário</font></font></li>
                  <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Telefone: (18) 99689-1342</font></font></li>
                </ul>
              </div>
              <div className="col-5 text-center">
                <img src="../../dist/img/johnny.jpg" alt="avatar de usuário" className="img-circle img-fluid" />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="text-right">
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="card-footer text-center">
    <div className='row'>
        <div className="col" style={{color: '#869099'}}>
            <strong>
        SUPORTE AN
        </strong>
        </div>
    </div>
  </div>
</div>
</section>
</div>

     <AppFooter />
     <AppSetting />
    </>
)}else{
    return(
        <>
         <AppHeader response={resp}/>
         <AppMenu />
         <div className="content-wrapper" style={{minHeight: '1604.44px'}}>
           <section className="content-header">
             <div className="container-fluid">
               <div className="row mb-2">
                 <div className="col-sm-6">
                   <h1><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Contatos</font></font></h1>
                 </div>
                
               </div>
             </div>
          </section>
  <section className="content">
    <div className="card card-solid">
      <div className="card-body pb-0">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
            <div className="card bg-light d-flex flex-fill">
              <div className="card-header text-muted border-bottom-0"><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>
                    Departamento Trabalhista
                  </font></font></div>
              <div className="card-body pt-0">
                <div className="row">
                  <div className="col-7">
                    <h2 className="lead"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Arthur Sanches</font></font></b></h2>
                    <p className="text-muted text-sm"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Sobre:</font></font></b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}> ​​Sócio / Advogado Gestor da Trabalhista</font></font></p>
                    <ul className="ml-4 mb-0 fa-ul text-muted">
                      <li className="small"><span className="fa-li"><i className="fas fa-lg fa-building" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Endereço: ...</font></font></li>
                      <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Telefone: (14) 99788-8112</font></font></li>
                    </ul>
                  </div>
                  <div className="col-5 text-center">
                    <img src="../../dist/img/arthur.png" alt="avatar de usuário" className="img-circle img-fluid" />
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="text-right">
                                  </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
            <div className="card bg-light d-flex flex-fill">
              <div className="card-header text-muted border-bottom-0"><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>
                    Departamento Empresarial
                  </font></font></div>
              <div className="card-body pt-0">
                <div className="row">
                  <div className="col-7">
                    <h2 className="lead"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Carlos Eduardo Ribeiro Pugliezi</font></font></b></h2>
                    <p className="text-muted text-sm"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Sobre:</font></font></b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}> ​Advogado Empresarial</font></font></p>
                    <ul className="ml-4 mb-0 fa-ul text-muted">
                      <li className="small"><span className="fa-li"><i className="fas fa-lg fa-building" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Endereço: Marechal Rondon, 101, AP 103</font></font></li>
                      <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Telefone: (18) 99729-8468</font></font></li>
                    </ul>
                  </div>
                  <div className="col-5 text-center">
                    <img src="../../dist/img/cadu.png" alt="avatar de usuário" className="img-circle img-fluid" />
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="text-right">
                                 </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
            <div className="card bg-light d-flex flex-fill">
              <div className="card-header text-muted border-bottom-0"><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>
                    Departamento de Tecnologia
                  </font></font></div>
              <div className="card-body pt-0">
                <div className="row">
                  <div className="col-7">
                    <h2 className="lead"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Jonathan Novaes</font></font></b></h2>
                    <p className="text-muted text-sm"><b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Sobre:</font></font></b><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}> ​​Desenvolvedor Full Stack</font></font></p>
                    <ul className="ml-4 mb-0 fa-ul text-muted">
                      <li className="small"><span className="fa-li"><i className="fas fa-lg fa-building" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Endereço: Reverendo João Batista Ribeiro Neto, 30, PQ. Universitário</font></font></li>
                      <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone" /></span><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Telefone: (18) 99689-1342</font></font></li>
                    </ul>
                  </div>
                  <div className="col-5 text-center">
                    <img src="../../dist/img/johnny.jpg" alt="avatar de usuário" className="img-circle img-fluid" />
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="text-right">
                                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer text-center">
        <div className='row'>
            <div className="col" style={{color: '#869099'}}>
                <strong>
            SUPORTE AN
            </strong>
            </div>
        </div>
      </div>
    </div>
  </section>
</div>

         <AppFooter />
         <AppSetting />
        </>
    )}  
}

export default Suporte
